const initState = {
    all_employee_scheduel_data : [],
    create: null,
    update:null,
    auto_schedule: []
}

const employeeScheduleReducer = (state = initState, action) => {
    switch (action.type) {
      case 'GetAllGroupData_SUCCESS':
        return {
          ...state,
          all_employee_scheduel_data: action.payload,
          errorcode: null,
        }
      case 'AutoSchedule_SUCCESS':
        return {
          ...state,
          auto_schedule: action.payload,
          errorcode: null,
        }
      case 'CreateData_SUCCESS':
        return {
          ...state,
          create: action.payload,
          errorcode: null,
        }
      case 'UpdateData_SUCCESS':
        return {
          ...state,
          update: action.payload,
          errorcode: null,
        }
      case 'EmployeeSchedule_Error':
        // console.log(action)
        return {
          ...state,
          errorcode: action.payload ? action.payload : 500,
        }
      default:
        return state
    }
}
export default employeeScheduleReducer

