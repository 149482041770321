const initState = {
  unclosed_list_by_project: [],
  unclosed_chart_data: {},
  unclosed_ip_chart_data: {},
  unclosed_projects: [],
  errorcode: null,
}

const unclosedListReducer = (state = initState, action) => {
  switch(action.type) {
    case 'GetUnclosedTicketList_SUCCESS':
      return {
        ...state,
        unclosed_list_by_project: action.payload,
        errorcode: null,
      }
    case 'GetUnclosedListChartByAcctNo_SUCCESS':
      return {
        ...state,
        unclosed_chart_data: action.payload,
        errorcode: null,
      }
    case 'GetUnclosedIPChartByAcctNo_SUCCESS':
      return {
        ...state,
        unclosed_ip_chart_data: action.payload,
        errorcode: null,
      }
    case 'GetUnclosedListProject_SUCCESS':
      return {
        ...state,
        unclosed_projects: action.payload,
        errorcode: null,
      }
    case 'Error':
      return {
        ...state,
        unclosed_list_by_project: null,
        errorcode: action.payload ? action.payload : 500,
      }
    default:
      return state
  }
}
export default unclosedListReducer