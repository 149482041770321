const initState = {
  client_rules: [],
  client_rules_update: [],
  workspace_by_project_no: [],
  client_rules_by_workspace: [],
  errorcode: null,
}

const clientSecurityRuleReducer = (state = initState, action) => {
  switch (action.type) {
    case 'Error':
      return {
        errorcode: action.payload ? action.payload : 500,
      }
    case 'FindAllWorkspaceByProjectNo_SUCCESS':
      return {
        ...state,
        workspace_by_project_no: action.payload,
        errorcode: null,
      }
    case 'FindSecurityRuleByWorkspaceNo_SUCCESS':
      return {
        ...state,
        client_rules_by_workspace: action.payload,
        errorcode: null,
      }
    case 'FindSecurityRuleByIdAndProvider_SUCCESS':
      return {
        ...state,
        client_rules: action.payload,
        errorcode: null,
      }
    case 'UpdateSecurityRuleByNo_SUCCESS':
      return {
        ...state,
        client_rules_update: action.payload,
        errorcode: null,
      }
    default:
      return state
  }
}
export default clientSecurityRuleReducer