const initState = {
  PeopleGroupCIClass: null,
  PeopleGroupCI:[],
  errorcode: null,
}

const cmdbPeopleGroupCIReducer = (state = initState, action) => {
  switch (action.type) {

    case 'Error':
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500
      }
    case 'GetPeopleGroupCIClass':
      return {
        ...state,
        PeopleGroupCIClass: action.PeopleGroupCIClass,
        errorcode: null
      }

    case 'GetPeopleGroupCIByNo':
      // console.log(action.PeopleGroupCI)
      return {
        ...state,
        PeopleGroupCI: [...state.PeopleGroupCI, action.PeopleGroupCI],
        errorcode: null
      }

    default:
      return state;
  }

}

export default cmdbPeopleGroupCIReducer
