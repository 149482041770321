import { produce } from 'immer';

const initState = {
  owner_by_comp_no: [],
  company_by_acct: [],
  errorcode: null,
  del_error_msg: null,
  add_error_msg: null,
  company_by_no: null,
  companys_by_noList: [],
  company_member_by_account: [],
  edit_company_member: null,
  company_member_by_account_company: [],
  eng_name_company: [],
  company_member_by_companyNo: [],
  company_group_member_by_company_member_no: [],
  company_group: [],
  create_member: undefined,
  comp_group_not_in_comp_member: [],
  add_comp_group_member: null,
  delete_comp_group_member: null,
  invite_comp_member: null,
  invite_error_msg: null,
  comp_group: null,
  comp_group_error_msg: null,
  comp_member_by_no_list: [],
  comp_member_error_msg: null,
  create_comp_group: null,
  create_comp_group_error_msg: null,
  edit_comp_group: null,
  edit_comp_group_error_msg: null,
  non_comp_group_member: null,
  non_comp_group_member_error_msg: null,
  comp_group_member: null,
  comp_group_member_error_msg: null,
  del_comp_group: null,
  del_comp_group_error_msg: null,
};

const companyReducer = produce((state = initState, action) => {
  switch (action.type) {
    case 'FindCompanysByNoList':
      return {
        ...state,
        companys_by_noList: action.payload,
        errorcode: null,
      };
    case 'FindCompSubSysOwnersByCompNo_SUCCESS':
      return {
        ...state,
        owner_by_comp_no: action.payload,
        errorcode: null,
      };
    case 'GetCompanyMemberByAcctNoAndCompNo_SUCCESS':
      return {
        ...state,
        company_member_by_account_company: action.payload,
        errorcode: null,
      };
    case 'FindCompanyMembersByAcctNo_SUCCESS':
      state.company_member_by_account = action.payload;
      return state;
    case 'EditCompanyMember_SUCCESS':
      state.edit_company_member = action.payload;
      return state;
    case 'FindCompanyByNo_SUCCESS':
      return {
        ...state,
        company_by_no: action.payload,
        errorcode: null,
      };
    case 'FindCompanysByAcct_SUCCESS':
      return {
        ...state,
        company_by_acct: action.payload,
        errorcode: null,
      };
    case 'FindAllEngNameCompany_SUCCESS':
      return {
        ...state,
        eng_name_company: action.payload,
        errorcode: null,
      };
    case 'GetCompanyMembersByCompanyNo_SUCCESS':
      return {
        ...state,
        company_member_by_companyNo: action.payload,
        errorcode: null,
      };
    case 'GetCompanyGroupMembersByCompanyMemberNo_SUCCESS':
      return {
        ...state,
        company_group_member_by_company_member_no: action.payload,
        errorcode: null,
      };
    case 'GetCompanyGroupsByNoList_SUCCESS':
      return {
        ...state,
        company_group: action.payload,
        errorcode: null,
      };
    case 'CreateCompanyMember_SUCCESS':
      return {
        ...state,
        create_member: action.payload,
        errorcode: null,
      };
    case 'GetCompanyGroupNotInCompMember_SUCCESS':
      return {
        ...state,
        comp_group_not_in_comp_member: action.payload,
        errorcode: null,
      };
    case 'AddCompanyGroupMember_SUCCESS':
      return {
        ...state,
        add_comp_group_member: action.payload,
        add_error_msg: null,
      };
    case 'AddCompanyGroupMember_FAIL':
      return {
        ...state,
        add_comp_group_member: null,
        add_error_msg: action.payload ? action.payload : 500,
      };
    case 'DeleteCompanyGroupMember_SUCCESS':
      return {
        ...state,
        delete_comp_group_member: action.payload,
        del_error_msg: null,
      };
    case 'DeleteCompanyGroupMember_FAIL':
      return {
        ...state,
        delete_comp_group_member: null,
        del_error_msg: action.payload ? action.payload : 500,
      };
    case 'InviteCompanyMembers_SUCCESS':
      return {
        ...state,
        invite_comp_member: action.payload,
        invite_error_msg: null,
      };
    case 'InviteCompanyMembers_FAIL':
      return {
        ...state,
        invite_comp_member: null,
        invite_error_msg: action.payload ? action.payload : 500,
      };
    case 'FindAllCompGroupsByCompNo_SUCCESS':
      return {
        ...state,
        comp_group: action.payload,
        comp_group_error_msg: null,
      };
    case 'FindAllCompGroupsByCompNo_FAIL':
      return {
        ...state,
        comp_group: null,
        comp_group_error_msg: action.payload ? action.payload : 500,
      };
    case 'GetCompanyMembersByNoList_SUCCESS':
      return {
        ...state,
        comp_member_by_no_list: action.payload,
        comp_member_error_msg: null,
      };
    case 'GetCompanyMembersByNoList_FAIL':
      return {
        ...state,
        comp_member_by_no_list: [],
        comp_member_error_msg: action.payload ? action.payload : 500,
      };

    case 'CreateCompanyGroup_SUCCESS':
      return {
        ...state,
        create_comp_group: action.payload,
        create_comp_group_error_msg: null,
      };
    case 'CreateCompanyGroup_FAIL':
      return {
        ...state,
        create_comp_group: null,
        create_comp_group_error_msg: action.payload.response.data.code === '2' ? '最上層部門已經存在' : 500,
      };

    case 'EditCompanyGroup_SUCCESS':
      return {
        ...state,
        edit_comp_group: action.payload,
        edit_comp_group_error_msg: null,
      };
    case 'EditCompanyGroup_FAIL':
      return {
        ...state,
        edit_comp_group: null,
        edit_comp_group_error_msg: action.payload? action.payload : 500,
      };

    case 'DeleteCompanyGroup_SUCCESS':
      return {
        ...state,
        del_comp_group: action.payload,
        del_comp_group_error_msg: null,
      };
    case 'DeleteCompanyGroup_FAIL':
      return {
        ...state,
        del_comp_group: null,
        del_comp_group_error_msg: action.payload? action.payload : 500,
      };
    case 'GetNonCompGroupMemberByGroupNo_SUCCESS':
      return {
        ...state,
        non_comp_group_member: action.payload,
        non_comp_group_member_error_msg: null,
      };
    case 'GetNonCompGroupMemberByGroupNo_FAIL':
      return {
        ...state,
        non_comp_group_member: null,
        non_comp_group_member_error_msg: action.payload ? action.payload : 500,
      };
    case 'GetCompanyGroupMembersByCompanyGroupNo_SUCCESS':
      return {
        ...state,
        comp_group_member: action.payload,
        comp_group_member_error_msg: null,
      };
    case 'GetCompanyGroupMembersByCompanyGroupNo_FAIL':
      return {
        ...state,
        comp_group_member: null,
        comp_group_member_error_msg: action.payload ? action.payload : 500,
      };

    case 'Error':
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      };
    default:
      return state;
  }
});
export default companyReducer;
