import { useState } from 'react';
import { Link } from 'react-router-dom';
import AppMobileMenuDropdown from './AppMobileMenuDropdown';

const AppMobileMenuItems = (props) => {
    const { tl, tlns } = props;
    let { items, depthLevel, showMenu, setShowMenu } = props;
    const [dropdown, setDropdown] = useState(false);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
        showMenu && setShowMenu(false);
    };

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdown((prev) => !prev);
    };

    return (
        <li className="menu-items" onClick={closeDropdown}>
            {items.path && items.submenu ? (
                <>
                    <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? 'true' : 'false'}>
                        <Link to={items.path} onClick={closeDropdown}>
                            {tl(tlns, items.title)}
                        </Link>
                        <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? <span className="arrow-close" /> : <span className="arrow" />}
                        </div>
                    </button>
                    <AppMobileMenuDropdown
                        {...props}
                        depthLevel={depthLevel}
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : !items.path && items.submenu ? (
                <>
                    <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? 'true' : 'false'}>
                        {tl(tlns, items.title)}{' '}
                        <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? <span className="arrow-close" /> : <span className="arrow" />}
                        </div>
                    </button>
                    <AppMobileMenuDropdown
                        {...props}
                        depthLevel={depthLevel}
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <Link to={items.path}>{tl(tlns, items.title)}</Link>
            )}
        </li>
    );
};

export default AppMobileMenuItems;
