import React, { cloneElement } from 'react';
import { Route, Routes} from 'react-router-dom';
import routes from '../../configs/portal/portal-routes';
import { Spinner } from 'reactstrap';
import HocPageSpin from './HocPageSpin';

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { createMsalConfig } from '../../configs/AzureSSOauthConfig';

const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
        <Spinner style={{ marginTop: '50px', marginBottom: '50px' }} color="dark" />
    </div>
);

const AppContent = props => {
    const fullUri = window.location.href;
    const msalID = JSON.parse(localStorage.getItem('msal.config.id')) ? JSON.parse(localStorage.getItem('msal.config.id')) : {};
    const msalConfig = createMsalConfig(msalID.clientId, msalID.tenantId);
    const msalInstance = new PublicClientApplication(msalConfig);

    const isAzureSSO = () => {
        if (
            fullUri != null &&
            fullUri.search('client_info') !== -1 &&
            fullUri.search('session_state') !== -1 &&
            fullUri.search('state') !== -1
        ) {
            return true;
        }
        return false;
    };

    const isReturnAzureSSO = () => {
        if (
            fullUri != null &&
            fullUri.search('error') !== -1 &&
            fullUri.search('error_subcode') !== -1 &&
            fullUri.search('state') !== -1
        ) {
            return true;
        }
        return false;
    };

    const MainContent = () => {
        const { instance } = useMsal();
        const accLists = instance.getAllAccounts();
        localStorage.setItem('isAzureSSO', true);
        return (
            <></>
        );
    };

    const BindingHocElement = HocPageSpin((propsFromHoc) => (
        <Routes forceRefresh={false}>
            {routes.map((route, i) =>
                    route.children ? 
                    (
                        route.children.map((pet, index) => 
                        (
                            <Route 
                                key={index} 
                                element={cloneElement(pet.element, {...propsFromHoc})} 
                                path={pet.path} {...props} 
                            />
                        )
                    )
                    ) : ( <Route 
                            key={i} 
                            path={route.path}
                            element={cloneElement(route.element, {...propsFromHoc})} 
                        />
                    )
                )
            }
            
        </Routes>
    ));

    if (isAzureSSO()) {
        return (
            <MsalProvider instance={msalInstance}>
                <MainContent />
            </MsalProvider>
        );
    } else {
        if (isReturnAzureSSO()) {
            document.location.assign(document.location.origin + '/');
        }
        return (
            <React.Suspense fallback={loading()}>
                <BindingHocElement
                    {...props}
                />
            </React.Suspense>
        );
    }
}

export default AppContent;
