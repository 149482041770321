import { produce } from "immer";

const initState = {
  ticket_status: [],
  ticket_status_by_project: {},
  ticket_status_by_category: {},
  errorcode: null,
};

const ticketStatusReducer = produce((state = initState, action) => {
  switch (action.type) {
    case "GetStatusByNo_SUCCESS":
      // console.log(action)
      return {
        ...state,
        ticket_status: [...state.ticket_status, action.payload],
        errorcode: null,
      };

    case "Error":
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500,
      };

    case "GetStatusByCategoryNo":
      state.ticket_status_by_category[action.category_no] = action.statusGroups;
      return state;
    // return {
    //   ...state,
    //   ticket_status_by_category: {
    //     ...state.ticket_status_by_category,
    //   },
    //   errorcode: null,
    // };

    case "GetStatusByProjectNo":
      state.ticket_status_by_project[action.project_no] = action.statusGroups;
      return state;
    // return {
    //     ...state,
    //     ticket_status_by_project: {
    //         ...state.ticket_status_by_project
    //     },
    //     errorcode: null
    // }
    default:
      return state;
  }
});

export default ticketStatusReducer;
