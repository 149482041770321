const initState = {
    ticket_levels: [],
    ticket_level_by_category: {},
    ticket_level_by_acct: [],
    errorcode: null,
}

const ticketLevelReducer = (state = initState, action) => {

    switch (action.type) {
        case 'Error':
            // console.log(action)
            return {
                ...state,
                // tickets: null,
                errorcode: action.payload ? action.payload : 500
            }

        case 'GetTicketCategoryLevelByCategoryNo':
            state.ticket_level_by_category[action.category_no] = action.payload

            return {
                ...state,
                ticket_level_by_category: {
                    ...state.ticket_level_by_category
                },
                errorcode: null
            }
        case 'FindTicketCategoryLevelByAcctNo_SUCCESS':
          return {
            ...state,
            ticket_level_by_acct: action.payload,
            errorcode: null,
          }
        default:
            return state;
    }
}

export default ticketLevelReducer
