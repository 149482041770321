import { UserPage } from '../../../js/Base/UserPage';

export class ProjectAction extends UserPage {
    ServiceName = 'proj';

    FindManageableProjectByCompanyNoList =
        (companyNoList, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'FindManageableProjectByCompanyNoList',
                { company_no_list: companyNoList },
                (res) => {
                    dispatch({ type: 'FindManageableProjectByCompanyNoList_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    FindAllEnableProjects =
        (success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'FindAllEnableProjects',
                {},
                (res) => {
                    dispatch({ type: 'FindAllEnableProjects_SUCCESS', payload: res.data.data });
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                }
            );
        };

    EditProject =
        (project, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'EditProject',
                { project: project },
                (res) => {
                    dispatch({ type: 'EditProject_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    GetMyProjectList =
        (acct_no, success_callback = null, error_callback = null) =>
        async (disptch, getState) => {
            const method_name = 'FindProjectByAcctNo';

            if (!acct_no) acct_no = this.GetUser().no;

            await this.WebApi(
                this.ServiceName,
                method_name,
                { acct_no: acct_no },
                (res) => {
                    // console.log(res)
                    const resData = res.data.data;
                    disptch({ type: 'MyProject_SUCCESS', payload: resData });
                    if (success_callback) success_callback(resData);
                },
                (err) => {
                    disptch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    GetProjectByNo = (project_no) => async (disptch, getState) => {
        if (
            !getState().project.my_project.find((p) => p.no === project_no) &&
            !getState().project.projects.find((p) => p.no === project_no)
        ) {
            const method_name = 'FindProjectByNo';
            await this.WebApi(
                this.ServiceName,
                method_name,
                { no: project_no },
                (res) => {
                    disptch({ type: 'GetProjectByNo_SUCCESS', payload: res.data.data });
                },
                (err) => {
                    disptch({ type: 'Project_Error', payload: err });
                }
            );
        }
    };

    GetOneProjectByNo =
        (project_no, success_callback = null, error_callback = null) =>
        async (disptch, getState) => {
            const method_name = 'FindProjectByNo';
            await this.WebApi(
                this.ServiceName,
                method_name,
                { no: project_no },
                (res) => {
                    disptch({ type: 'GetOneProjectByNo_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    disptch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    GetGodaAuthTypes =
        (success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'FindAllGodaProjectAuthTypes',
                {},
                (res) => {
                    dispatch({ type: 'FindAllGodaProjectAuthTypes_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    CreateGodaRole =
        (godaRole, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'CreateGodaRole',
                { goda_role: godaRole },
                (res) => {
                    dispatch({ type: 'CreateGodaRole_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    EditGodaRole =
        (godaRole, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'EditGodaRole',
                { goda_role: godaRole },
                (res) => {
                    dispatch({ type: 'EditGodaRole_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    GetGodaRolesByProjectNo =
        (projectNo, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'FindGodaRolesByProjectNo',
                { project_no: projectNo },
                (res) => {
                    dispatch({ type: 'GetGodaRolesByProjectNo_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    CreateGodaMemberRole =
        (projectMemberNo, roleNo, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'CreateGodaMemberRole',
                {
                    project_member_no: projectMemberNo,
                    role_no: roleNo,
                },
                (res) => {
                    dispatch({ type: 'CreateGodaMemberRole_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    DeleteGodaMemberRole =
        (projectMemberNo, roleNo, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            await this.WebApi(
                this.ServiceName,
                'DeleteGodaMemberRole',
                {
                    project_member_no: projectMemberNo,
                    role_no: roleNo,
                },
                (res) => {
                    dispatch({ type: 'DeleteGodaMemberRole_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    GetProjectMembersByAcctNo =
        (acctNo, projectNo, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            const data = {
                acct_no: acctNo,
                project_no: projectNo,
            };
            await this.WebApi(
                this.ServiceName,
                'GetProjectMembersByAcctNo',
                data,
                (res) => {
                    dispatch({ type: 'GetProjectMembersByAcctNo_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    GetProjectMembersByProjectNoNoPage =
        (projectNo, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            const data = {
                project_no: projectNo,
            };
            await this.WebApi(
                this.ServiceName,
                'GetProjectMembersByProjectNoNoPage',
                data,
                (res) => {
                    dispatch({ type: 'GetProjectMembersByProjectNoNoPage_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    FindProjectMembersByProjectNo =
        (projectNo, page, pageSize, searchKeyword, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            const data = {
                project_no: projectNo,
                page: page,
                page_size: pageSize,
                search: searchKeyword,
            };
            await this.WebApi(
                this.ServiceName,
                'FindProjectMembersByProjectNo',
                data,
                (res) => {
                    dispatch({ type: 'FindProjectMembersByProjectNo_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };

    FindProjectRolesByAcctNo =
        (acctNo, success_callback = null, error_callback = null) =>
        async (dispatch, getState) => {
            if (!acctNo) {
                acctNo = this.GetUser().no;
            }
            await this.WebApi(
                this.ServiceName,
                'FindProjectRolesByAcctNo',
                { acct_no: acctNo },
                (res) => {
                    dispatch({ type: 'FindProjectRolesByAcctNo_SUCCESS', payload: res.data.data });
                    if (success_callback) success_callback(res.data.data);
                },
                (err) => {
                    dispatch({ type: 'Project_Error', payload: err });
                    if (error_callback) error_callback(err);
                }
            );
        };
}
