const initState = {
    inviteCompany: null,
    errorcode:null
}

const inviteReducer = (state = initState, action) => {
    
    switch(action.type) {

        case 'GetInvite_SUCCESS':
            // console.log(action.payload.data.data)
            return {
                ...state,
                inviteCompany: action.payload.data.data,
                errorcode: null
            }
        case 'SetInvite_SUCCESS':
            return {
                ...state,
                inviteCompany: state.inviteCompany.filter(i=>i.no !== action.payload.data.data.no),
                errorcode: null
            }
        case 'Invite_ERROR':
            // console.log(action)
            const errorcode =  action.payload.response? action.payload.response.status: 500
            return {
                ...state,
                inviteCompany: null,
                errorcode: errorcode
            }
        default:
            return state;
    }
}

export default inviteReducer