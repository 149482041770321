import React, { useEffect, useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import menus from '../../configs/portal/portal-menus';
import AppMobileMenuItems from './AppMobileMenuItems';
import './AppMobileMenu.css';

const AppMobileMenu = (props) => {
    const depthLevel = 0;
    const [showMenu, setShowMenu] = useState(false);
    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (showMenu && ref.current && !ref.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [showMenu]);

    return (
        <nav className="mobile-nav">
            <button className="mobile-nav__menu-button" type="button" onClick={() => setShowMenu((prev) => !prev)}>
                <MenuIcon />
            </button>

            {showMenu && (
                <ul className="menus" ref={ref}>
                    {menus.map((menu, index) => {
                        return (
                            <AppMobileMenuItems
                                items={menu}
                                key={'mobile-menu' + index}
                                depthLevel={depthLevel}
                                showMenu={showMenu}
                                setShowMenu={setShowMenu}
                                {...props}
                            />
                        );
                    })}
                </ul>
            )}
        </nav>
    );
};

export default AppMobileMenu;
