const initState = {
  accts: [],
  acct_by_id: {},
  create_forget_pwd: null,
  pwd_id_using: false,
  create_acct: {},
  errorcode: null,
  get_accts_by_group: [],
  manager_acct_list: [],
  manager_acct_list_errorcode: null,
  acct_list: [],
  acct_list_errorcode: null,
};

const acctReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CreateForgetPwdByAcctID_SUCCESS':
      return {
        ...state,
        create_forget_pwd: action.payload,
        errorcode: null,
      };
    case 'CheckForgetPwdByID_SUCCESS':
      return {
        ...state,
        pwd_id_using: action.payload,
        errorcode: null,
      };
    case 'SetForgetPwd_SUCCESS':
      return {
        ...state,
        pwd_id_using: action.payload,
        errorcode: null,
      };
    case 'GetAccountByNo_SUCCESS':
      //  console.log(action)
      return {
        ...state,
        accts: [...state.accts, action.payload],
        errorcode: null,
      };
    case 'FindAccountsByCompanyGroupNo_SUCCESS':
      return {
        ...state,
        get_accts_by_group: action.payload,
        errorcode: null,
      };
    case 'GetAccountByID_SUCCESS':
      return {
        ...state,
        acct_by_id: action.payload,
        errorcode: null,
      };
    case 'CreateAccount_SUCCESS':
      return {
        ...state,
        create_acct: action.payload,
        errorcode: null,
      };
    case 'GetManagerAccountsByNoList_SUCCESS':
      return {
        ...state,
        manager_acct_list: action.payload,
        errorcode: null,
      };
    case 'GetAccountsByNoList_SUCCESS':
      return {
        ...state,
        acct_list: action.payload,
        errorcode: null,
      };
    case 'GetManagerAccountsByNoList_FAIL':
      return {
        ...state,
        manager_acct_list: [],
        errorcode: action.payload ? action.payload : 500,
      };
    case 'GetAccountsByNoList_FAIL':
      return {
        ...state,
        acct_list: [],
        errorcode: action.payload ? action.payload : 500,
      };
    case 'Account_Error':
      // console.log(action)
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      };
    default:
      return state;
  }
};

export default acctReducer;
