import React, {useEffect, useState} from 'react';
import { withTranslation } from 'react-i18next';
import PortalLayout from './PortalLayout';
import AdminLayout from './AdminLayout';
import { useLoaderData } from 'react-router-dom';

const CloudPortal = (props) => {
    const token = useLoaderData('root');

    const {t} = props;

    const tl = (key, val) => {
        return(t(`${key}.${val}`));
    };
    
    return (
        token 
        ? <AdminLayout tl={tl}  {...props} />
        : <PortalLayout  tl={tl} {...props} />
    );
}

export default withTranslation()(CloudPortal);