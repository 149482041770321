const initState = {
  all_folder: [],
  dashboard_by_uid: {},
  new_dashboard: {},
  data_source_by_name: {},
  new_data_source: {},
}

const grafanaReducer = (state = initState, action) => {
  switch (action.type) {
    case 'Error':
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      }
    case 'FindAllDashboardFolder_SUCCESS':
      return {
        ...state,
        all_folder: action.payload,
        errorcode: null,
      }
    case 'GetDashboardInfoByUid_SUCCESS':
      return {
        ...state,
        dashboard_by_uid: action.payload,
        errorcode: null,
      }
    case 'CreateDashboard_SUCCESS':
      return {
        ...state,
        new_dashboard: action.payload,
        errorcode: null,
      }
    case 'GetDataSourceByWorkspaceName_SUCCESS':
      return {
        ...state,
        data_source_by_name: action.payload,
        errorcode: null,
      }
    case 'CreateDataSource_SUCCESS':
      return {
        ...state,
        new_data_source: action.payload,
        errorcode: null,
      }
    default:
      return state
  }
}
export default grafanaReducer
