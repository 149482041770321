import React from 'react';

// const
export const CFG_ENABLE_CMDB = 'EnableCMDB';
export const CFG_ENABLE_NOTIFY = 'EnableNotify';
export const CLOSE_Sentinel_INCIDENT = 'CloseSentinelIncident';
export const CHECK_TICKET_QUANTITY = 'CheckTicketQuantity';
export const AUTO_CLOSE_TICKET = 'AutoCloseTicket';
export const CHECK_DEFENDER_TICKET_QUANTITY = 'CheckDefenderTicketQuantity';

// enum
export const OperateModeTypeEnum = Object.freeze({
  Create: 0,
  Edit: 1,
  Search: 2,
  Design: 3,
  Preview: 4,
})

export const isNullOrUndefined = obj => {
  return obj === null || obj === undefined || typeof obj === 'undefined'
}

export const equals = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const deepcopy = obj => {
  var _ = require('lodash')
  return _.cloneDeep(obj)
}

export const filter_delete = ozObjs => {
  return ozObjs.filter(ozObj => !ozObj.deleted)
}

export const generateUUID = () => {
  const { v4: uuidv4 } = require('uuid')
  return uuidv4()
}

export const breakLine = text => {
  if (!text) return text
  var regex = /(\n)/g
  return text.split(regex).map(function (line, index) {
    return line.match(regex) ? <br key={'key_' + index} /> : line
  })
}

export const GetConfig = async () => {
  const config_key = 'config'
  let config = localStorage.getItem(config_key)
  if (!config) {
    const res = await fetch('/config.json')
    config = await res.text()
    localStorage.setItem(config_key, config)
  }
  return JSON.parse(config)
}

export const GetConfig1 = async () => {
  const config_key = 'config'
  const res = await fetch('/config.json')
  const configText = await res.text()
  localStorage.setItem(config_key, configText)
  const config = localStorage.getItem(config_key)
  return JSON.parse(config)
}
