import { produce } from "immer";

const initState = {
  all_manageable_project: [],
  all_enable_project: [],
  my_project: [],
  projects: [],
  one_project: {},
  goda_auth_types: [],
  new_goda_role: null,
  edit_goda_role: null,
  goda_role_by_project: [],
  new_goda_member_role: null,
  delete_goda_member_role: false,
  project_members_by_project_no: null,
  errorcode: null,
  edit_project: null,
};

const projectReducer = produce((state = initState, action) => {
  switch (action.type) {
    case "FindManageableProjectByCompanyNoList_SUCCESS":
      state.all_manageable_project = action.payload
      return state
    case "FindAllEnableProjects_SUCCESS":
      return {
        ...state,
        all_enable_project: action.payload,
        errorcode: null,
      };
    case "EditProject_SUCCESS":
      return {
        ...state,
        edit_project: action.payload,
        errorcode: null,
      };
    case "MyProject_SUCCESS":
      state.my_project = action.payload
      return state
    case "GetProjectByNo_SUCCESS":
      return {
        ...state,
        projects: [...state.projects, action.payload],
        errorcode: null,
      };
    case "GetOneProjectByNo_SUCCESS":
      return {
        ...state,
        one_project: action.payload,
        errorcode: null,
      };
    case "FindAllGodaProjectAuthTypes_SUCCESS":
      return {
        ...state,
        goda_auth_types: action.payload,
        errorcode: null,
      };
    case "CreateGodaRole_SUCCESS":
      return {
        ...state,
        new_goda_role: action.payload,
        errorcode: null,
      };
    case "EditGodaRole_SUCCESS":
      return {
        ...state,
        edit_goda_role: action.payload,
        errorcode: null,
      };
    case "GetGodaRolesByProjectNo_SUCCESS":
      return {
        ...state,
        goda_role_by_project: action.payload,
        errorcode: null,
      };
    case "CreateGodaMemberRole_SUCCESS":
      return {
        ...state,
        new_goda_member_role: action.payload,
        errorcode: null,
      };
    case "DeleteGodaMemberRole_SUCCESS":
      return {
        ...state,
        delete_goda_member_role: action.payload,
        errorcode: null,
      };
    case "FindProjectMembersByProjectNo_SUCCESS":
      return {
        ...state,
        project_members_by_project_no: action.payload,
        errorcode: null,
      };
    case "Project_Error":
      // console.log(action)
      return {
        ...state,
        my_project: null,
        errorcode: action.payload ? action.payload : 500,
      };
    default:
      return state;
  }
});

export default projectReducer;
