const initState = {
    userNav: 
    {
        items: [
            {
                name: 'Dashboard',
                url: '/dashboard',
                icon: 'icon-speedometer',
                // badge: {
                //   variant: 'info',
                //   // text: 'NEW',
                // },
            }
        ]
    },
    errorcode: null
}

const navReducer = (state = initState, action) => {

    switch(action.type) {

        case 'GetNav_SUCCESS':
            // console.log(action.payload.data.data)
            return {
                ...state,
                userNav: action.payload,
                errorcode: null
            }
        case 'Nav_ERROR':
            // console.log(action)
            return {
                ...state,
                userNav: null,
                errorcode: action.payload? action.payload: 500
            }
        default:
            return state;
    }
}

export default navReducer