import React from 'react';

import { 
  createBrowserRouter, 
  RouterProvider,
} from 'react-router-dom';
import routes from './configs/portal/portal-routes';
import { withTranslation } from 'react-i18next';
import CloudPortal from './layout/CloudPortal';

const App = props => {
  const router = createBrowserRouter(routes);

  return (
    <RouterProvider router={router} >
      <CloudPortal  />
    </RouterProvider>
  );
}


export default  withTranslation()(App);
