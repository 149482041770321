import React from 'react';
import { Spinner } from 'reactstrap';

const spin_loading = () => (
    <div className="animated fadeIn pt-3 text-center">
        <Spinner style={{ marginTop: '50px', marginBottom: '50px' }} color="dark" />
    </div>
);

const HocPageSpin = (Component) => class extends React.Component {
    constructor() {
        super();
        this.state = {
            pageReady: false,
        };
        this._pageLoading = this._pageLoading.bind(this);
        this._pageFinish = this._pageFinish.bind(this);
    }
    // static getDerivedStateFromProps(props, state) {
    //     if ('location' in nextProps) {
    //         if ('pathname' in nextProps.location) {
    //             if (
    //                 nextProps.location.pathname !== this.props.location.pathname  &&
    //                 !nextProps.location.pathname.includes('/')
    //             ) {
    //                 this.setState({ pageReady: false });
    //             }
    //         }
    //     }
    // }
    _pageLoading(callback = () => {}) {
        const thisCallback = callback.bind(this);
        this.setState({
            pageReady: false,
        }, () => {
            thisCallback();
        });
    }
    _pageFinish(callback = () => {}) {
        const thisCallback = callback.bind(this);
        this.setState({
            pageReady: true,
        }, () => {
            thisCallback();
        });
    }
    render() {
        const { pageReady } = this.state;
        const pageSpin = {
            status: pageReady,
            _loading: this._pageLoading,
            _finish: this._pageFinish,
        };
        return (
            <>
                {pageReady ? (
                    <Component pageSpin={pageSpin} {...this.props} />
                ) : (
                    <React.Suspense fallback={spin_loading()}>
                        <Component pageSpin={pageSpin} {...this.props} />
                    </React.Suspense>
                )}
            </>
        );
    }
};

export default HocPageSpin;
