const initState = {
  app_key_by_sub: {},
  app_key_by_proj: [],
  sub_list: [],
}

const cloudAppKeyReducer = (state = initState, action) => {
  switch (action.type) {
    case 'Error':
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      }
    case 'FindAppKeyBySubscription_SUCCESS':
      return {
        ...state,
        app_key_by_sub: action.payload,
        errorcode: null,
      }
    case 'FindAllAppKeyByProject_SUCCESS':
      return {
        ...state,
        app_key_by_proj: action.payload,
        errorcode: null,
      }
    case 'FindSubscriptionByAppKey_SUCCESS':
      return {
        ...state,
        sub_list: action.payload,
        errorcode: null,
      }
    default:
      return state
  }
}
export default cloudAppKeyReducer