const initState = {
  advisor_count: [],
  risk_list: [],
  unHealthyResourcesList : [],
  pdf_report: {},
  excel_report: {},
};

const godaSecurityReducer = (state = initState, action) => {
  switch (action.type) {
    case "Error":
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      };
    case "GetAdvisorCount_SUCCESS":
      return {
        ...state,
        advisor_count: action.payload,
        errorcode: null,
      };
    case "GetRiskList_SUCCESS":
      return {
        ...state,
        risk_list: action.payload,
        errorcode: null,
      };
    case "GetUnHealthyResourcesList_SUCCESS":
      return {
        ...state,
        unHealthyResourcesList: action.payload,
        errorcode: null,
      };

    case "DownloadRegulatoryCompliancePdfReport_SUCCESS":
      return {
        ...state,
        pdf_report: action.payload,
        errorcode: null,
      };

    case "DownloadRegulatoryComplianceCsvReport_SUCCESS":
      return {
        ...state,
        excel_report: action.payload,
        errorcode: null,
      };

    default:
      return state;
  }
};
export default godaSecurityReducer;
