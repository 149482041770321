import { TreeBeardNode } from "./struct";

class BasicEnum {
  static Enum = {};
  static getKeyByValue(value) {
    return Object.keys(this.Enum).find((key) => "" + this.Enum[key] === "" + value);
  }
}

export class TextType extends BasicEnum {
  static Enum = Object.freeze({
    Number: 0,
    Text: 1,
    IP: 2,
    DateTime: 3,
    Date: 4,
    Email: 5,
    Telephone: 6,
    Cellphone: 7,
  });
}

export class InputBoxType extends BasicEnum {
  static Enum = Object.freeze({
    TextBox: 0,
    TextArea: 1,
    DropDown: 2,
    MultiLevelDropDown: 3,
    CheckBox: 4,
    ComboBox: 5,
    DatePicker: 7,
    DateTimePicker: 9,
    Table: 10,
    TableColumn: 11,
    Relationship: 12,
  });
}

export class CmdbRelationType extends BasicEnum {
  static Enum = Object.freeze({
    CmdbClass: 0,
    OzCompanyGroup: 1,
    OzCompanyMember: 2,
  });
}

export const CI_NO_PREFIX = "CI-";
export const FIELD_PREFIX = "CUSTOM_FIELD_";
export const REF_ITEM_PREFIX = "CUSTOM_FIELD_ITEM_REF_";

export const constructCompanyGroupTree = (comGrps) => {
  if (comGrps.filter((comGrp) => comGrp.parent_no === null).length > 1) {
    console.error("多個公司組織 root");
    // Todo: 加上 Root 節點，將全部串在一起
  }

  const root = comGrps.find((comGrp) => comGrp.parent_no === null);
  if (root === undefined) {
    console.error("找不到公司組織的 root");
  }

  // convert to tree beard node
  let rootNode = null;
  const comGrpTBNodes = comGrps.map((comGrp) => new TreeBeardNode(comGrp.no, comGrp.name, comGrp.parent_no, false, []));

  // construct tree
  comGrpTBNodes.forEach((currentNode) => {
    const parentNode = comGrpTBNodes.find((node) => node.no === currentNode.parentNo);
    if (parentNode) {
      parentNode.children.push(currentNode);
    } else {
      rootNode = currentNode;
    }
  });

  // set children as null if it is empty
  // 若無 children，TreeBeard 會將節點設為無法展開
  comGrpTBNodes.forEach((comGrpTBNode) => {
    if (comGrpTBNode.children.length === 0) {
      comGrpTBNode.children = null;
    }
  });

  return rootNode;
};
