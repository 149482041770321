import AppMobileMenuItems from './AppMobileMenuItems';

const AppMobileMenuDropdown = (props) => {
    let { submenus, dropdown, depthLevel } = props;
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';

    return (
        <ul className={`dropdown ${dropdownClass} ${dropdown ? 'show' : ''}`}>
            {submenus.map((submenu, index) => (
                <AppMobileMenuItems
                    {...props}
                    items={submenu}
                    key={'mobile-sub-menu' + index}
                    depthLevel={depthLevel}
                />
            ))}
        </ul>
    );
};

export default AppMobileMenuDropdown;
