let menus = [
    {
        title: 'overview',
        path: '/overview',
        pattern: ['/overview'],
        submenu: [
            //CloudGoda介紹
            {
                title: 'cloudgoda-intro',
                desc: 'cloudgoda-intro-desc',
                path: '/cloudgoda',
                pattern: ['/cloudgoda'],
            },
            //訂閱方案
            {
                title: 'subscription',
                desc: 'subscription-desc',
                path: '/overview/subscription',
                pattern: ['/overview/subscription'],
            },
            //所有服務
            {
                title: 'all-service',
                desc: 'all-service-desc',
                path: '/overview/allservice',
                pattern: ['/overview/allservice'],
            },
        ],
    },
    {
        title: 'pricingcomparison',
        path: '/pricingcomparison',
        pattern: ['/pricecomparison'],
        submenu: [
            //雲端服務商查詢
            {
                title: 'provider-search',
                desc: 'provider-search-desc',
                path: '/pricecomparison',
                pattern: ['/pricecomparison'],
            },
            //網路延遲性檢測
            {
                title: 'latency',
                desc: 'latency-desc',
                path: '/latency-testing',
                pattern: ['/latency-testing'],
            },
        ],
    },
    {
        title: 'deploy',
        path: '/deploy',
        pattern: ['/deploy'],
        submenu: [
            {
                title: 'design-and-deploy',
                desc: 'design-and-deploy-desc',
                path: '/deploy',
                pattern: ['/deploy'],
            },
        ],
    },
    {
        title: 'cloud-security',
        path: '/cloud-security',
        pattern: ['/cloud-security'],
        submenu: [
            //安全性態勢
            {
                title: 'security-posture',
                desc: 'security-posture-desc',
                path: '/cloud-security#security-posture',
                pattern: ['/cloud-security'],
            },
            //法規合規性
            {
                title: 'regulatory-compliance',
                desc: 'regulatory-compliance-desc',
                path: '/cloud-security#regulatory-compliance',
                pattern: ['/cloud-security'],
            },
            //安全性建議
            {
                title: 'security-recommendation',
                desc: 'security-recommendation-desc',
                path: '/cloud-security#security-recommendation',
                pattern: ['/cloud-security'],
            },
            //Flowza
            {
                title: 'flowza',
                desc: 'flowza-desc',
                path: '/flowza',
                pattern: ['/flowza'],
            },
            //Cloud SOC 規則管理
            {
                title: 'cloud-soc-rule',
                desc: 'cloud-soc-rule-desc',
                path: '/cloud-security#cloud-soc-rule',
                pattern: ['/cloud-security'],
            },
        ],
    },
    {
        title: 'smart-management',
        path: '/smart-management',
        pattern: ['/smart-management'],
        submenu: [
            //成本分析
            {
                title: 'cost-analysis',
                desc: 'cost-analysis-desc',
                path: '/smart-management#cost-analysis',
                pattern: ['/smart-management'],
            },
            //智能化建議
            {
                title: 'smart-recommendations',
                desc: 'smart-recommendations-desc',
                path: '/smart-management#smart-recommendations',
                pattern: ['/smart-management'],
            },
        ],
    },
    {
        title: 'operation-excellent',
        path: '/operation-excellent',
        pattern: ['/operation-excellent'],
        submenu: [
            //資源資產組態
            {
                title: 'resource-asset-configuration',
                desc: 'resource-asset-configuration-desc',
                path: '/operation-excellent#resource-asset-configuration',
                pattern: ['/operation-excellent'],
            },
            //監控儀表板
            {
                title: 'resource-dashboard',
                desc: 'resource-dashboard-desc',
                path: '/operation-excellent#resource-dashboard',
                pattern: ['/operation-excellent'],
            },
            //服務狀態健康檢查
            {
                title: 'service-health-check',
                desc: 'service-health-check-desc',
                path: '/operation-excellent#service-health-check',
                pattern: ['/operation-excellent'],
            },
        ],
    },
    {
        title: 'cloudbackup',
        path: '/cloud-backup',
        pattern: ['/cloud-backup'],
        submenu: [
            {
                title: 'job-overview',
                desc: 'job-overview-desc',
                path: '/cloud-backup',
                pattern: ['/cloud-backup'],
            },
        ],
    },
    {
        title: 'goda-mall',
        path: '/goda-mall',
        pattern: ['/overview'],
        submenu: [
            {
                title: 'goda-mall',
                desc: 'goda-mall-desc',
                path: '/goda-mall',
                pattern: ['/goda-mall'],
            },
        ],
    },
];

export default menus;
