import { createSlice } from '@reduxjs/toolkit';


export const loginMenuSlice = createSlice({
    name: 'loginMenu',
    initialState: {
        projectNo: localStorage.getItem("project_no") ? localStorage.getItem("project_no") : 0,
        companyNo: localStorage.getItem("company_no") ? localStorage.getItem("company_no") : 0,
        menuDropdownOpen: false,
        openSideBar: null,
        firstMenu: null, 
        selectedFirstMenu: null, 
        secondMenu: null, 
        thirdMenu: null,
        thirdMenuList: [],
    },
    reducers: {
        setProjectNo(state, action) {
            state.projectNo = action.payload;
            localStorage.setItem("project_no", action.payload);
        },
        setCompanyNo(state, action) {
            state.companyNo = action.payload;
            localStorage.setItem("company_no", action.payload);
        },
        setFirstMenu(state, action) {
            state.firstMenu = action.payload;
        },
        setSelectFirstMenu(state, action) {
            state.selectedFirstMenu = action.payload;
            localStorage.setItem("selectedFirstMenu", action.payload);
        },
        removeSelectFirstMenu(state) {
            state.selectedFirstMenu = null;
            localStorage.removeItem("selectedFirstMenu");
        },
        setSecondMenu(state, action) {
            state.secondMenu = action.payload;
            localStorage.setItem("secondMenu", action.payload);
        },
        removeSecondMenu(state) {
            state.secondMenu = null;
            localStorage.removeItem("secondMenu");
        },
        setThirdMenu(state, action) {
            state.thirdMenu = action.payload;
            localStorage.setItem("thirdMenu", action.payload);
        },
        removeThirdMenu(state) {
            state.thirdMenu = null;
            localStorage.removeItem("thirdMenu");
        },
        setThirdMenuList(state, action) {
            state.thirdMenuList = action.payload;
            localStorage.setItem("thirdMenuList", JSON.stringify(action.payload));
        },
        removeThirdMenuList(state) {
            state.thirdMenuList = null;
            localStorage.removeItem('thirdMenuList');
        },
        setMenuDropdown(state) {
            state.menuDropdownOpen = !state.menuDropdownOpen;
        },
        setMenuDropdownByVal(state, action) {
            state.menuDropdownOpen = action.payload;
        },
        setSidebar(state) {
            state.openSideBar = !state.openSideBar;
            localStorage.setItem('openSideBar', state.openSideBar )
        },
        setSidebarByVal(state, action) {
            state.openSideBar = action.payload;
            localStorage.setItem("openSideBar", action.payload);
        },
        removeAllMenu(state) {
            state.firstMenu = null;
            state.selectedFirstMenu = null;
            state.secondMenu = null;
            state.thirdMenu = null;
            state.thirdMenuList = null;
            state.menuDropdownOpen = false;
            localStorage.removeItem("selectedFirstMenu");
            localStorage.removeItem("secondMenu");
            localStorage.removeItem("thirdMenu");
            localStorage.removeItem("thirdMenuList");
        },
    },
});

export default loginMenuSlice.reducer;
export const loginMenuAction = loginMenuSlice.actions;
