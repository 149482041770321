import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import AppLoginMenuItems from './AppLoginMenuItems';
import { Row, Col, Container } from 'react-bootstrap';
import { getMenus } from '../../configs/admin/admin-menus';
import AppLoginMenuDropdown from './AppLoginMenuDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { loginMenuAction } from '../../store/loginMenuSlice';

const AppLoginMenu = (props) => {
  const { tl, tlns } = props;
  const [secondMenuCol, setSecondMenuCol] = useState('');
  const [dropdownHeight, setDropdownHeight] = useState('260px');
  const [lastY, setLastY] = useState(0);
  const [menus, setMenus] = useState([]);
  const dispatch = useDispatch();
  const firstMenu = useSelector((state) => state.loginMenu.firstMenu);
  const selectedFirstMenu = useSelector((state) => state.loginMenu.selectedFirstMenu);
  const menuDropdownOpen = useSelector((state) => state.loginMenu.menuDropdownOpen);
  const location = useLocation();
  const pathName = location.pathname === '/' ? '/console' : location.pathname;

  useEffect(() => {
    const getActiveMenu = (menus) => {
      let mappingMenu = false;
      const pathBeforeHash = pathName.includes('resource-asset-configuration')
        ? '/classification-list'
        : pathName.includes('performance-content')
        ? '/azure-performance'
        //公司管理
        : `/${pathName.split('/')[1]}` === ('/company')
        ? '/company-management'
        //Ticket
        : pathName.includes('ticket') && !pathName.includes('-')
        ? '/incident'
        //流程管理
        : pathName.includes('/project/mgmt')
        ? '/project/process-management'
        : pathName;

      for (const firstMenu of menus) {
        for (const secondMenu of firstMenu.menu) {
          if (secondMenu.hasOwnProperty('submenu')) {
            for (const thirdMenu of secondMenu.submenu) {
              if (thirdMenu.path === pathName || thirdMenu.path === pathBeforeHash) {
                dispatch(loginMenuAction.setSelectFirstMenu(firstMenu.title));
                dispatch(loginMenuAction.setSecondMenu(secondMenu.title));
                dispatch(loginMenuAction.setThirdMenu(thirdMenu.title));
                const filterMenu = secondMenu.submenu
                  .map((m) => {
                    if (!m.hasOwnProperty('show') || m.show === true) {
                      return m;
                    }
                    return null;
                  })
                  .filter((m) => m !== null);
                dispatch(loginMenuAction.setThirdMenuList(filterMenu));

                mappingMenu = true;
              }
            }
          } else {
            if (secondMenu.path === pathName) {
              dispatch(loginMenuAction.setSelectFirstMenu(firstMenu.title));
              dispatch(loginMenuAction.setSecondMenu(secondMenu.title));
              dispatch(loginMenuAction.setThirdMenu(null));
              dispatch(loginMenuAction.setThirdMenuList(null));
              dispatch(loginMenuAction.setSidebarByVal(false));
              mappingMenu = true;
            }
          }
        }
      }
      if (mappingMenu === false) {
        dispatch(loginMenuAction.setFirstMenu(null));
        dispatch(loginMenuAction.setSelectFirstMenu(null));
        dispatch(loginMenuAction.setSecondMenu(null));
        dispatch(loginMenuAction.setThirdMenu(null));
        dispatch(loginMenuAction.setThirdMenuList(null));
      }
    };
    if (menus.length > 0) {
      getActiveMenu(menus);
    }
  }, [dispatch, menus, pathName]);

  useEffect(() => {
    const userNav = JSON.parse(localStorage.getItem('userNav')) ? JSON.parse(localStorage.getItem('userNav')) : {};
    const menus = getMenus(userNav);
    setMenus(menus);

    const secondMenuCol = () => {
      let elements = [];
      for (let menu of menus) {
        if (menu.title === firstMenu) {
          let index = menus.indexOf(menu);
          elements.push(
            <AppLoginMenuDropdown
              {...props}
              dropdownHeight={dropdownHeight}
              setDropdownHeight={setDropdownHeight}
              menu={menu.menu}
              key={'menu' + index}
            />
          );
        }
      }
      return elements;
    };
    if (firstMenu) {
      setSecondMenuCol(secondMenuCol());
    }
  }, [firstMenu]);

  const onhandleMouseMove = (event) => {
    setLastY(event.clientY);
  };

  const onNoSelectHandler = (event) => {
    const currentY = event.clientY;
    //向下離開
    if (currentY > lastY) {
      dispatch(loginMenuAction.setMenuDropdownByVal(false));
    }
  };

  const onClickNoSelectHandler = () => {
    dispatch(loginMenuAction.setMenuDropdownByVal(false));
  };

  return (
    <div className="AppLoginMenu" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <nav className="desktop-nav">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ul className="menus">
            {menus.map((menu, index) => {
              return <AppLoginMenuItems {...props} items={menu} key={'menu' + index} selectMenu={selectedFirstMenu} />;
            })}
          </ul>
        </div>
      </nav>

      <div
        className="menu-dropdown"
        style={{ height: dropdownHeight, display: menuDropdownOpen ? 'block' : 'None', zIndex: 1030 }}
      >
        <Container fluid>
          <Row
            className="menuitem-dropdown"
            style={{ height: dropdownHeight }}
            onClick={onClickNoSelectHandler}
            onMouseMove={onhandleMouseMove}
            onMouseLeave={onNoSelectHandler}
          >
            {secondMenuCol}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AppLoginMenu;
