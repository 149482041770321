import React, { useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import './MobileButton.css';
import Button from 'react-bootstrap/Button';

const MobileButton = props => {
    useEffect(()=>{
        window.addEventListener('scroll',  scrollFunction);
        return () => {
            // fired on component unmount.
            window.removeEventListener('scroll',  scrollFunction);
        }
    },[])

    const scrollFunction = () => {
        let mybutton = document.getElementById("btn-back-to-top");
        if ( document.body.scrollTop > 20 ||  document.documentElement.scrollTop > 20 ) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <div onScroll={scrollFunction}>
            <Tooltip title="scroll to top">
                <IconButton onClick={backToTop} id="btn-back-to-top" class="btn-back-top">
                    <i class="bi bi-arrow-up-short"></i>
                </IconButton>
            </Tooltip>
            <Tooltip title="Acer eDC">
                <IconButton id="btn-edc-logo" class="btn-edc-logo">
                    Acer eDC
                </IconButton>
            </Tooltip>

        </div>
    );
}

export default MobileButton;
