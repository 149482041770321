// Config object to be passed to Msal on creation
export const createMsalConfig = (clientId, tenantId) => ({
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: document.location.origin + "/",
        knownAuthorities: ["login.microsoftonline.com"],
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
});

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};

export const logoutRequest = {
    postLogoutRedirectUri: document.location.origin + "/",
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
