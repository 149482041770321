const initState = {
    ticket_template_by_project: {},
    errorcode: null,
}


const ticketTemplateReducer = (state = initState, action) => {

    switch (action.type) {

        case 'Error':
            // console.log(action)
            return {
                ...state,
                // tickets: null,
                errorcode: action.payload ? action.payload : 500
            }

        case 'GetTicketTemplateByProjectNo_SUCCESS':
            state.ticket_template_by_project[action.project_no] = action.ticketTemplates

            return {
                ...state,
                ticket_template_by_project: {
                    ...state.ticket_template_by_project
                },
                errorcode: null
            }
        default:
            return state;
    }
}

export default ticketTemplateReducer
