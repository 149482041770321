import { toast } from 'react-toastify';


// 使用builder method添加react toastify options 
export class ToastAlert {
    constructor() {
        this.toastId = null;
        this.options = this._initOptions();
    }

    // product method
    showInfo(msg) {
        this.options = {
            ...this.options,
            render: msg,
            type: toast.TYPE.INFO,
            autoClose: this.options['autoClose'] ? 2000 : this.options['autoClose'],
            closeOnClick: this.options['autoClose'],
        };

        this._show();
    }

    // product method
    showError(msg) {
        this.options = {
            ...this.options,
            render: msg,
            type: toast.TYPE.ERROR,
            autoClose: this.options['autoClose'] ? 3000 : this.options['autoClose'],
        };

        this._show();
    }

    // product method
    showSuccess(msg) {
        this.options = {
            ...this.options,
            render: msg,
            type: toast.TYPE.SUCCESS,
            autoClose: this.options['autoClose'] ? 2000 : this.options['autoClose'],
            closeOnClick: this.options['autoClose'],
        };

        this._show();
    }

    // build method
    autoClose(auto = true) {
        this.options['autoClose'] = auto;
        return this;
    }

    dismiss() {
        toast.dismiss(this.toastId);
    }

    _show() {
        if (this.toastId) toast.update(this.toastId, this.options);
        else this.toastId = toast(this.options.render, this.options);
        this.options = this._initOptions();
    }

    _initOptions() {
        // default option
        return {
            autoClose: true,
        };
    }
}