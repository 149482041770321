const initState = {
  PeopleCIClass: null,
  PeopleCI:[],
  errorcode: null,
}

const cmdbPeopleCIReducer = (state = initState, action) => {
  switch (action.type) {

    case 'Error':
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500
      }
    case 'GetPeopleCIClass':
      return {
        ...state,
        PeopleCIClass: action.PeopleCIClass,
        errorcode: null
      }

    case 'GetPeopleCIByNo':
      return {
        ...state,
        PeopleCI: [...state.PeopleCI, action.PeopleCI],
        errorcode: null
      }

    default:
      return state;
  }

}

export default cmdbPeopleCIReducer
