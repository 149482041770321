import { CallWebApi } from './CallWebApi'
import { CallToGodaWebApi } from './CallWebApi'
import { Component } from 'react'

export class BasePage extends Component {
    WebApi = async (ServiceName, MethodName, props, config = null, success_action = null, error_action = null) => {
        return await CallWebApi(ServiceName, MethodName, props, config, success_action, error_action);
    };

    ToGodaWebApi = async (
        ServiceName,
        MethodName,
        props,
        config = null,
        success_action = null,
        error_action = null
    ) => {
        return await CallToGodaWebApi(ServiceName, MethodName, props, config, success_action, error_action);
    };

    GetWebApiConfig = (token) => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        return config;
    };

    LoginBase = (user_data) => {
        const expiration = new Date();
        localStorage.setItem('token', user_data.token);
        localStorage.setItem('id', user_data.id);
        localStorage.setItem('lan', user_data.language);
        localStorage.setItem('name', user_data.name);
        localStorage.setItem('no', user_data.no);
        localStorage.setItem('timezone', user_data.timezone);
        expiration.setHours(expiration.getHours() + 1);
        localStorage.setItem('expiration', expiration.toISOString());

        //重整才能讀到user儲存語系
        // window.location.reload()
    };

    GoIndexPage = () => {
        //window.location.href = '/'; //在iphone不會轉頁
        document.location.assign(document.location.origin + '/');
    };
}
