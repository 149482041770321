import { produce } from "immer";

const initState = {
  ticket_by_no: null,
  tickets: [],
  ticket_files: [],
  errorcode: null,
};

const ticketReducer = produce((state = initState, action) => {
  switch (action.type) {
    case "Error":
      state.errorcode = action.payload ? action.payload : 500
      return state;
      // return {
      //   ...state,
      //   // tickets: null,
      //   errorcode: action.payload ? action.payload : 500,
      // };
    case "GetTicketByNo":
      state.tickets = [...state.tickets, action.ticket];
      return state;
    // return {
    //   ...state,
    //   tickets: state.tickets,
    //   errorcode: null
    // }
    case "UpdateTicket":
      state.tickets = [...state.tickets.filter((t) => t.no !== action.ticket.no), action.ticket];
      return state;
    // return {
    //   ...state,
    //   tickets: state.tickets,
    //   errorcode: null
    // }
    case "GetTicketFile_SUCCESS":
      state.ticket_files = action.payload;
      return state;
    // return {
    //   ...state,
    //   ticket_files: state.ticket_files,
    //   errorcode: null,
    // }
    case "DeleteReducerTicketByNo":
      state.tickets = [...state.tickets.filter((t) => t.no !== action.ticket_no)];
      return state;
      // return {
      //   ...state,
      //   tickets: state.tickets,
      //   errorcode: null,
      // };
    default:
      return state;
  }
});

export default ticketReducer;
