import React from 'react'
import { ERoleAuthorityCode } from '../../../js/Domain/ERoleAuthorityCode';
import { Translation } from 'react-i18next'
import { UserPage } from '../../../js/Base/UserPage';

export class GetUserNavAction extends UserPage {

  GetUserNav = (acctNo, projectNo, companyNo, userWebApiConfig) => async (dispatch, getState) => {
      //僅顯示一個專案的menu
      let ticketCategorys = await this.__GetTicketCategoryObj(projectNo, userWebApiConfig);
      let authTicket = await this.__GetAuthMenuStatus(projectNo, companyNo, ticketCategorys, acctNo, userWebApiConfig);
      let authComp = await this.__GetManagerCompany(acctNo, userWebApiConfig);
      // }
      let authMenu = {
          ...authTicket,
          ...authComp
      }
      localStorage.setItem("userNav", JSON.stringify(authMenu))
      // dispatch({ type: 'GetNav_SUCCESS', payload: authMenu })
    // } else if (projects === 500) {
    //   console.log('nav:' + projects)
    //   // window.location = '/500' //在iphone不會轉頁
    //   // document.location.assign(document.location.origin + '/forgetpwd') //先不要轉500，在iphone剛看首頁就會500
    // } else {
    //   dispatch({ type: 'Nav_ERROR', payload: 401 })
    // }
  }

  __GetManagerCompany = async (acct_no, userWebApiConfig) => {
    let show_settings = false;
    let adminAuth = {
      corp_management: false,
      process_management: false,
      cmdb_setting: false,
      system_setting: false
    };

    //公司管理
    let companys = await this.__GetManagerCompanyObj(acct_no, userWebApiConfig);
    if (companys && companys.length > 0) {
      adminAuth.corp_management = true;
      adminAuth.cmdb_setting = true;
      show_settings = true;
    }
    //流程管理
    let roles = await this.__GetMyProjectRoles(acct_no, userWebApiConfig);
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.project_management))) {
      adminAuth.process_management = true;
      show_settings = true;
    }
    
    if (show_settings) {
      adminAuth.system_setting = true;
    }

    return adminAuth
  }

  __GetSocReport = async (acct_no, userWebApiConfig, projectNo, roles) => {
    let nav = {}
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.soc_report) && r.project_no === projectNo)) {
      nav = {
        name: <Translation>{t => t('soc-report')}</Translation>,
        url: `/report_soc/${projectNo}`,
        icon: 'cui-pie-chart',
      }
    } else {
      return null
    }
    return nav
  }

  __GetEmployeeSchedule = async (acct_no, userWebApiConfig, projectNo, roles) => {
    let nav = {}
    // let roles = await GetMyProjectRoles(acct_no, userWebApiConfig)
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.employee_schedule) && r.project_no === projectNo)) {
      nav = {
        name: <Translation>{t => t('shift-sched-management')}</Translation>,
        url: `/employee_schedule/${projectNo}`,
        icon: 'cui-pie-chart',
      }
    } else {
      return null
    }
    return nav
  }

  __GetServicePortalReport = async (acct_no, userWebApiConfig, projectNo, roles) => {
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.service_portal_download) && r.project_no === projectNo)) {
      return true
    }
    return null
  }

  __GetUnclosedListByUser = async (acctNo, userWebApiConfig, projectNo, roles, compOwners) => {
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.unclosed_tickets_by_user) && r.project_no === projectNo)) {
      if (compOwners) {
        const isSubOwner = compOwners.some(val => val.acct_no === acctNo)
        return isSubOwner
      }
    }
    return null
  }

  __GetUnclosedListByIP = async (acctNo, userWebApiConfig, projectNo, roles, compOwners) => {
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.unclosed_tickets_by_ip) && r.project_no === projectNo)) {
      if (compOwners) {
        const isSubOwner = compOwners.some(val => val.acct_no === acctNo)
        return isSubOwner
      }
    }
    return null
  }

  __GetDashboardDisplay = async (acctNo, userWebApiConfig, projectNo, roles) => {
    if (roles.some(r => r.authority_code.includes(ERoleAuthorityCode.dashboard_display) && r.project_no === projectNo)) {
      return {
        name: 'Dashboard',
        url: `/dashboard-display/${projectNo}`,
        icon: 'cui-dashboard',
      }
    }
    return null
  }

  __GetMgmtReport = async (acct_no, userWebApiConfig, projectNo) => {
    const nav = {
      name: <Translation>{t => t('營運報表')}</Translation>,
      url: `/report_mgmt/${projectNo}`,
      icon: 'cui-monitor',
    }
    return nav
  }

  __GetCDBMItem = async (acct_no, userWebApiConfig) => {
    //   const companies = (await GetManagerCompanyObj(acct_no, userWebApiConfig)).map((companyDict) =>
    //     new OzCompany().fromDict(companyDict)
    //   );
    //   let enableCmdbCompanyNos = companies
    //     .map((company) => company.getCmdbConfig())
    //     .filter((cfg) => cfg && isTrue(cfg.value))
    //     .map((cfg) => cfg.company_no);

    //   // 過濾重複
    //   enableCmdbCompanyNos = distinct(enableCmdbCompanyNos);

    //   if (enableCmdbCompanyNos.length > 0) {
    // const eDC = companies.find(company => company.name === "AcerEDC");
    let cmdb = [
      {
        title: true,
        name: <Translation>{t => t('CMDB')}</Translation>,
      },
      {
        name: <Translation>{t => t('config-management')}</Translation>,
        url: '/cmdb/ci-type/list',
        icon: 'icon-pie-chart',
      },
      // {
      //     name: "PM - 建立專案 CI",
      //     //url: `/cmdb/ci/eDCPMCreateProjectCI/${eDC.no}`,
      //     url: `/cmdb/ci/eDCPMCreateProjectCI/2`,
      //     icon: "icon-pie-chart",
      // },
    ]
    return cmdb
    //   }
    //   return [];
  }

  __GetAuthMenuStatus = async (project, companyNo, ticketCategorys, acct_no, userWebApiConfig) => {
    let projectChildrenItem = await this.__GetProjectChildrenItem(
      project,
      companyNo,
      ticketCategorys,
      acct_no,
      userWebApiConfig
    );
    return projectChildrenItem
  }

  __GetProjectChildrenItem = async (projectNo, companyNo, ticketCategorys, acct_no, userWebApiConfig) => {
    let authObj = {
      soc_report: false,
      employee_schedule: false,
      service_portal: false,
      unclosed_list_by_user: false,
      unclosed_list_by_ip: false,
      dashboard_display: false,
    };
    const roles = await this.__GetMyProjectRoles(acct_no, userWebApiConfig)
    let soc_report = await this.__GetSocReport(acct_no, userWebApiConfig, projectNo, roles);
    let employee_schedule = await this.__GetEmployeeSchedule(acct_no, userWebApiConfig, projectNo, roles);
    let service_portal = await this.__GetServicePortalReport(acct_no, userWebApiConfig, projectNo, roles);
    const compOwners = await this.__GetCompSubSysOwners(companyNo, userWebApiConfig);
    let unclosed_list_by_user = await this.__GetUnclosedListByUser(acct_no, userWebApiConfig, projectNo, roles, compOwners)
    let unclosed_list_by_ip = await this.__GetUnclosedListByIP(acct_no, userWebApiConfig, projectNo, roles, compOwners);
    let dashboard_display = await this.__GetDashboardDisplay(acct_no, userWebApiConfig, projectNo, roles);
    let mgmt_report = await this.__GetMgmtReport(acct_no, userWebApiConfig, projectNo);

    if (soc_report){
      authObj.soc_report = true;
    } 
    if (employee_schedule){
      authObj.employee_schedule = true;
    }
    if (service_portal){
      authObj.service_portal = true;
    }
    if (unclosed_list_by_user){
      authObj.unclosed_list_by_user = true;
    } 
    if (unclosed_list_by_ip){
      authObj.unclosed_list_by_ip = true;
    } 
    if (dashboard_display){
      authObj.dashboard_display = true;
    } 

    return authObj;
  }

  __GetMyProjectRoles = async (acct_no, userWebApiConfig) => {
    let companys = await this.WebApi2('proj', 'FindProjectRolesByAcctNo', { acct_no: acct_no });
    return companys
  }

  __GetCompSubSysOwners = async (companyNo, userWebApiConfig) => {
    let companys = this.WebApi2('comp', 'FindCompSubSysOwnersByCompNo', { comp_no: companyNo });

    return companys
  }

  __GetManagerCompanyObj = async (acct_no, userWebApiConfig) => {
    let companys = await this.WebApi2('comp', 'FindManageableCompanies', { acct_no: acct_no });

    return companys
  }

  __GetCompanyObj = async (acct_no, userWebApiConfig) => {
    let companys = await this.WebApi2('comp', 'FindCompanyMembersByAcctNo', { acct_no: acct_no });
    return companys
  }

  __GetTicketCategoryObj = async (project_no, userWebApiConfig) => {
    let projects = await this.WebApi2(
      'ticket/setting',
      'FindTicketCategoryByProjectNo',
      { project_no: project_no }
    );

    return projects
  }
}


