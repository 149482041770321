// import ENV from './ENV';

// const baseUrl = {
//     PROD: 'https://cloudgoda.aceredc.com',
//     TEST: 'https://godatest.aceredc.com',
//     DEV: 'http://cloudgoda.intra.aceredc.com:8081',
// };
// const flowzaUrl = {
//     PROD: 'https://socflowza.aceredc.com',
//     TEST: 'https://flowzademo.aceredc.com',
//     DEV: 'http://127.0.0.1:3000',
// };
// const flowzaBackendUrl = {
//     PROD: 'https://socflowza.aceredc.com',
//     TEST: 'https://flowzademo.aceredc.com',
//     DEV: 'http://127.0.0.1:8000',
// };
// const cspUrl = {
//     PROD: 'https://csp.aceredc.com',
//     TEST: 'http://210.241.158.56',
//     DEV: 'http://210.241.158.56',
// };
// const csmUrl = {
//     PROD: 'https://csm.aceredc.com',
//     TEST: 'https://csm.aceredc.com',
//     DEV: 'https://csm.aceredc.com',
// };
// const gaTrackId = {
//     PROD: 'UA-19185811-7',
//     TEST: 'UA-126992259-1',
//     DEV: 'UA-126992259-1',
// };
// const baseHost = baseUrl[ENV];
// const flowzaHost = flowzaUrl[ENV];
// const flowzaBackendHost = flowzaBackendUrl[ENV];
// const basePath = {
//     flowzaLoginSSo: flowzaHost + '/LoginSSo',
//     flowzaAcct: flowzaBackendHost + '/WebApi/acct',
//     price: baseHost + '/api/price/list',
//     priceForIbm: baseHost + '/api/priceibm/list',
//     images: baseHost + '/api/images/list',
//     imagesForIbm: baseHost + '/api/imagesibm/list',
//     region: baseHost + '/api/region/list',
//     deployment: baseHost + '/api/infra',
//     account: baseHost + '/api/account',
//     chatbot: baseHost + '/api/chatbot',
//     subscription: baseHost + '/api/subscription',
//     library: baseHost + '/api/library',
//     contact: baseHost + '/api/contact',
//     log: baseHost + '/api/log',
//     cost: baseHost + '/api/cost',
//     accountLink: baseHost + '/api/accountlink',
//     customAccountLink: baseHost + '/api/customaccountlink',
//     azureCost: baseHost + '/api/azurecost',
//     autoScan: baseHost + '/api/autoscan',
//     security: baseHost + '/api/security',
//     monitor: baseHost + '/api/monitor',
//     serviceHealth: baseHost + '/api/serviceHealth',
//     classificationOfCost: baseHost + '/api/classificationOfCost',
//     cloudConnectInfrastructure: baseHost + '/api/cloudConnectInfrastructure',
//     backupInfrastructure: baseHost + '/api/backUpInfrastructure',
//     company: baseHost + '/api/company',
//     jobOverview: baseHost + '/api/jobOverview',
// };
const globalVars = {
    //     name: 'Cloud Portal',
    //     apiUrl: {
    //         verifySSoLoginToken: basePath.flowzaAcct + '/VerifySSoLoginToken',
    //         sendTokenToFlowza: basePath.flowzaLoginSSo,
    //         createToken: basePath.account + '/auth_manager/createToken',
    //         verifyFlowzaToken: basePath.account + '/auth_manager/verifyFlowzaToken',
    //         ssoLogin: basePath.account + '/auth_manager/ssoLogin',
    //         saveFlowzaToken: basePath.account + '/auth_manager/saveFlowzaToken',
    //         getMenuDisplayAuth: basePath.account + '/auth_manager/getMenuDisplayAuth',
    //         currencyRate: baseHost + '/api/price/currencyrate/all',
    //         detailByProviderLoc: basePath.price + '/_design/serviceInfo/_list/querydetail/detailbyproviderloc',
    //         computingByLocation: basePath.price + '/_design/serviceInfo/_list/querybyspec/computingbylocation',
    //         computingByProviderLoc: basePath.price + '/_design/serviceInfo/_list/querybyproviderloc/computingbyproviderloc',
    //         computingByPrice: basePath.price + '/_design/serviceInfo/_list/querybyprice/computingbyprice',
    //         gcpos: basePath.price + '/_design/serviceInfo/_list/querygcpos/gcpos',
    //         detailByProviderLocForIbm: basePath.priceForIbm + '/_design/serviceInfo/_list/querydetail/detailbyproviderloc',
    //         computingByLocationForIbm: basePath.priceForIbm + '/_design/serviceInfo/_list/querybyspec/computingbylocation',
    //         computingByProviderLocForIbm:
    //             basePath.priceForIbm + '/_design/serviceInfo/_list/querybyproviderloc/computingbyproviderloc',
    //         computingByPriceForIbm: basePath.priceForIbm + '/_design/serviceInfo/_list/querybyprice/computingbyprice',
    //         queryregionsForIbm: basePath.priceForIbm + '_design/serviceInfo/_list/queryregions/regions',
    //         ibmos: basePath.priceForIbm + '/_design/serviceInfo/_list/queryibmos/ibmos',
    //         imageByCategory: basePath.images + '/_design/imageInfo/_list/queryimage/imagebycategory',
    //         imageByRegion: basePath.images + '/_design/imageInfo/_list/queryimagebyregion/imagebyregion',
    //         os: basePath.images + '/_design/imageInfo/_list/queryos/os',
    //         getEdcVmMachines: baseHost + '/api/images/GetAllInstanceType',
    //         getEdcVmImages: baseHost + '/api/images/getEDCAllImages',
    //         getAwsLoggerImageByRegion: baseHost + '/api/images/getImageIDbyRegion',
    //         getEdcLoggerInstanceType: baseHost + '/api/images/GetSafe3InstanceType',
    //         getEdcLoggerImages: baseHost + '/api/images/GetSafe3Image',
    //         getIbmLoggerInstanceType: baseHost + '/api/priceibm/getLoggerInstanceType',
    //         imageByCategoryForIbm: basePath.imagesForIbm + '/_design/imageInfo/_list/queryimage/imagebycategory',
    //         imageByRegionForIbm: basePath.imagesForIbm + '/_design/imageInfo/_list/queryimagebyregion/imagebyregion',
    //         osForIbm: basePath.imagesForIbm + '/_design/imageInfo/_list/queryos/os',
    //         regionCountry: basePath.region + '/_design/regionInfo/_list/queryregioncountry/regioncountry',
    //         templateList: basePath.deployment + '/template_manager/list',
    //         templateGet: basePath.deployment + '/template_manager/get',
    //         templateSave: basePath.deployment + '/template_manager/save',
    //         templateDelete: basePath.deployment + '/template_manager/delete',
    //         listPlatformsAws: basePath.deployment + '/template_manager/list_web_app_platforms',
    //         listDBInstance: basePath.deployment + '/template_manager/list_db_instance',
    //         listLambdaRuntime: basePath.deployment + '/template_manager/list_lambda_runtime',
    //         listDeployRecord: basePath.deployment + '/template_manager/list_deploy_record',
    //         deploymentAws: basePath.deployment + '/deployment_manager/deploy_aws',
    //         deploymentGcp: basePath.deployment + '/deployment_manager/deploy_gcp',
    //         deploymentIbm: basePath.deployment + '/deployment_manager/deploy_ibm',
    //         deploymentEdc: basePath.deployment + '/deployment_manager/deploy_edc',
    //         getKeyListAws: basePath.deployment + '/deployment_manager/get_key_list_aws',
    //         createKeyAws: basePath.deployment + '/deployment_manager/create_key_aws',
    //         deleteAws: basePath.deployment + '/deployment_manager/delete_aws',
    //         deleteGcp: basePath.deployment + '/deployment_manager/delete_gcp',
    //         deleteIbm: basePath.deployment + '/deployment_manager/delete_ibm',
    //         deleteEdc: basePath.deployment + '/deployment_manager/delete_edc',
    //         createAccount: basePath.account + '/create',
    //         accountInfo: basePath.account + '/list/_design/accountInfo/_list/queryaccount/account',
    //         modifyAccount: basePath.account + '/modify',
    //         resetPasswordRequest: basePath.account + '/resetpwdreq',
    //         resetPassword: basePath.account + '/resetpassword',
    //         login: basePath.account + '/auth_manager/login',
    //         logout: basePath.account + '/auth_manager/logout',
    //         azureSSOLogin: basePath.account + '/auth_manager/azure_sso_login',
    //         authToken: basePath.account + '/auth_manager/auth_token',
    //         userGetChatbotConfig: basePath.chatbot + '/userGetChatbotConfig',
    //         subscriptionLatest: basePath.subscription + '/latest',
    //         subscriptionCreate: basePath.subscription + '/create',
    //         subscriptionList: basePath.subscription + '/list',
    //         subscriptionCancel: basePath.subscription + '/cancel',
    //         subscriptionGetInvoice: basePath.subscription + '/genInvoicePDF',
    //         contactCreate: basePath.contact + '/create',
    //         listAllCategoryInfo: basePath.library + '/category_manager/list',
    //         listAllLibraryInfo: basePath.library + '/library_manager/list',
    //         addLog: basePath.log + '/addLog',
    //         testCost: basePath.cost + '/test',
    //         getPredefinedCost: basePath.cost + '/predefined',
    //         getDailyCostDetail: basePath.cost + '/getDailyDetails',
    //         getDailyCost: basePath.cost + '/getdailycost',
    //         getCostByGroup: basePath.cost + '/getcostbygroup',
    //         getDimensionValues: basePath.cost + '/dimensionvalues',
    //         getCostAlarmList: basePath.cost + '/getalarmlist',
    //         costAlarm: basePath.cost + '/alarm',
    //         costAlarmSub: basePath.cost + '/alarmsub',
    //         costLinkedAccounts: basePath.cost + '/linkedAccounts',
    //         accountLink: basePath.accountLink + '/link',
    //         customAccountLink: basePath.customAccountLink + '/link',
    //         shortCustomAccountLink: basePath.customAccountLink + '/shortLink',
    //         getCostForecast: basePath.cost + '/getcostbyForecast',
    //         getBudgets: basePath.cost + '/budgets',
    //         setScanStatus: basePath.customAccountLink + '/setScanStatus',
    //         azureVerify: basePath.customAccountLink + '/verifyAzureStatus',
    //         azureCostLinkedAccounts: basePath.azureCost + '/linkedAccounts',
    //         getAzureCostAlarmList: basePath.azureCost + '/getalarmlist',
    //         azureCostAlarm: basePath.azureCost + '/alarm',
    //         azureSubscriptionList: basePath.autoScan + '/subscriptionList',
    //         azureVirtualNetworkList: basePath.autoScan + '/virtualNetworkList',
    //         azureVirtualMachineList: basePath.autoScan + '/virtualMachineList',
    //         getSecureScore: basePath.security + '/getsecurescore',
    //         getActiveRecommendations: basePath.security + '/getActiveRecommendations',
    //         getAllRecommendations: basePath.security + '/getAllRecommendations',
    //         getAzureAssessments: basePath.security + '/getAzureAssessments',
    //         getAffectedResources: basePath.security + '/getAffectedResources',
    //         getRegulaCompliStatus: basePath.security + '/getRegulaCompliStatus',
    //         getAzureAssessmentMeta: basePath.security + '/getAzureAssessmentMeta',
    //         getUnhealthyResources: basePath.security + '/getUnhealthyResources',
    //         getRegulatoryComplianceControls: basePath.security + '/getRegulatoryComplianceControls',
    //         downloadRegulatoryComplianceCsvReport: basePath.security + '/downloadRegulatoryComplianceCsvReport',
    //         downloadRegulatoryCompliancePdfReport: basePath.security + '/downloadRegulatoryCompliancePdfReport',
    //         azureSecurityGroupList: basePath.autoScan + '/networkSecurityGroupList',
    //         azureDiskList: basePath.autoScan + '/diskList',
    //         azureSubnetList: basePath.autoScan + '/subnetList',
    //         azurePublicIPAddressList: basePath.autoScan + '/publicIPAddressList',
    //         azureWebAppList: basePath.autoScan + '/webAppList',
    //         azureSqlDatabaseList: basePath.autoScan + '/sqlDatabaseList',
    //         azureLocationList: basePath.autoScan + '/regionList',
    //         azureLogAnalyticsList: basePath.autoScan + '/logAnalyticsList',
    //         azureAutoScanCount: basePath.autoScan + '/autoscanCount',
    //         azureAutoScanRefresh: basePath.autoScan + '/refresh',
    //         azureAutoScanDiagram: basePath.autoScan + '/structure',
    //         azureStorageAccountsList: basePath.autoScan + '/storageAccountsList',
    //         securityRefresh: basePath.security + '/refresh',
    //         securityRiskList: basePath.security + '/riskList',
    //         securityAdvisorCount: basePath.security + '/advisorCount',
    //         azureMonitorOverView: basePath.monitor + '/overview',
    //         azureMonitorVirtualMachineCPU: basePath.monitor + '/vmCPU',
    //         azureMonitorVirtualMachineDiskRead: basePath.monitor + '/vmDiskRead',
    //         azureMonitorVirtualMachineDiskWrite: basePath.monitor + '/vmDiskWrite',
    //         azureMonitorVirtualMachineNetworkInTotal: basePath.monitor + '/vmNetworkInTotal',
    //         azureMonitorVirtualMachineNetworkOutTotal: basePath.monitor + '/vmNetworkOutTotal',
    //         azureMonitorAppServiceCPU: basePath.monitor + '/appServiceCPU',
    //         azureMonitorAppServiceMemory: basePath.monitor + '/appServiceMemory',
    //         azureMonitorAppServiceDataIn: basePath.monitor + '/appServiceDataIn',
    //         azureMonitorAppServiceDataOut: basePath.monitor + '/appServiceDataOut',
    //         azureMonitorSqlDatabaseDataSpace: basePath.monitor + '/sqlDatabaseDataSpace',
    //         azureMonitorSqlDatabaseDTUPercentage: basePath.monitor + '/sqlDatabaseDTUPercentage',
    //         azureMonitorStorageAccountsUsedCapacity: basePath.monitor + '/storageAccountsUsedCapacity',
    //         azureMonitorDashboardPortal: basePath.monitor + '/azureDashboardPortal',
    //         getActiveServiceIssue: basePath.serviceHealth + '/getActiveServiceIssue',
    //         getActivePlannedMaintenance: basePath.serviceHealth + '/getActivePlannedMaintenance',
    //         getServiceListData: basePath.serviceHealth + '/getServiceListData',
    //         getHealthAlertActionVia: basePath.serviceHealth + '/getHealthAlertActionVia',
    //         getActivityLogs: basePath.serviceHealth + '/getActivityLogs',
    //         getActionGroupList: basePath.serviceHealth + '/getActionGroupList',
    //         getResourceGroupList: basePath.serviceHealth + '/getResourceGroupList',
    //         createOrUpdateActionGroup: basePath.serviceHealth + '/createOrUpdateActionGroup',
    //         createOrUpdateHealthAlert: basePath.serviceHealth + '/createOrUpdateHealthAlert',
    //         // deleteHealthAlert: basePath.serviceHealth + '/deleteHealthAlert',
    //         getCombinedData: basePath.serviceHealth + '/getCombinedData',
    //         getOrgMember: basePath.classificationOfCost + '/getOrgMember',
    //         scanResourceGroupTags: basePath.classificationOfCost + '/scanResourceGroupTags',
    //         updateOrgMemberGroupTags: basePath.classificationOfCost + '/updateOrgMemberGroupTags',
    //         insertOrgMemberGroupTags: basePath.classificationOfCost + '/insertOrgMemberGroupTags',
    //         getMapOrgMemberGroupTags: basePath.classificationOfCost + '/getMapOrgMemberGroupTags',
    //         getResourceGroupTags: basePath.classificationOfCost + '/getResourceGroupTags',
    //         getAccountByID: basePath.classificationOfCost + '/getAccountByID',
    //         getProjectByAcctNo: basePath.classificationOfCost + '/getProjectByAcctNo',
    //         getCompanyGroupByAcctNo: basePath.classificationOfCost + '/getCompanyGroupByAcctNo',
    //         getAzureDailyDetail: basePath.classificationOfCost + '/getAzureDailyDetail',
    //         getRepositories: basePath.cloudConnectInfrastructure + '/getRepositories',
    //         getCloudConnectInfrastructure: basePath.cloudConnectInfrastructure + '/getCloudConnectInfrastructure',
    //         getLatestAlarms: basePath.cloudConnectInfrastructure + '/getLatestAlarms',
    //         getBackupRepository: basePath.backupInfrastructure + '/getRepositories',
    //         getCompanyByNo: basePath.backupInfrastructure + '/getCompanyByNo',
    //         getBackupInfrastructure: basePath.backupInfrastructure + '/getBackupInfrastructure',
    //         getBackUpServerAlarms: basePath.backupInfrastructure + '/getBackUpServerAlarms',
    //         getVmJobs: basePath.backupInfrastructure + '/getVmJobs',
    //         getCompanyByTraffic: basePath.company + '/companyByTraffic',
    //         getBackUpServerJobData: basePath.jobOverview + '/getBackUpServerJobData',
    //     },

    //     cspHostUrl: cspUrl[ENV] + '/CSP-admin/login.aspx',
    //     csmHostUrl: csmUrl[ENV] + '/CSM/Account/Login.html',
    //     tokenAge: 2592000, // 30 days,
    recaptchSitekey: {
        normal: '6Lc9p0YpAAAAAMhkCxSUhRvybzGdLH-Z5dBDzDll',
    },
};

export default globalVars;
