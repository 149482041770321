import React, { Fragment } from 'react';
import AppMenu from './AppMenu';
import AppMobileMenu from './AppMobileMenu';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';


const AppHeader = props => {
    return (
        <Fragment>
            <Container fluid className= "AppHeader">
                <div className="nav-area">
                    <a href="/" className="logo">
                        <Image alt="Cloudgoda" src="/assets/images/edc-cp-logo-50.png" rounded />
                    </a>
                    <AppMenu {...props} />
                    <AppMobileMenu {...props} />
                </div>
            </Container>
        </Fragment>
    )
}

export default AppHeader;
