import React, { useState, useEffect } from 'react';

const LanguageSelector = () => {
    const [language, setLanguage] = useState(localStorage.getItem('lan') || 'zh');

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setLanguage(newLanguage);
        localStorage.setItem('lan', newLanguage);
        window.location.reload(); // This will refresh the page
    };

    return (
        <div className="language-selector">
            <select value={language} onChange={handleLanguageChange}>
                <option value="zh">中文(繁體)</option>
                <option value="en">English</option>
            </select>
        </div>
    );
};

export default LanguageSelector;
