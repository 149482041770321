export const GetStatusName = (value) => {
  switch (value) {
    case "{EDefaultTicketStatus.Draft}":
      return "Draft";
    case "{EDefaultTicketStatus.New}":
      return "New";
    case "{EDefaultTicketStatus.CLOSE}":
      return "Closed";
    default:
      return value;
  }
};

export const GetStatusNoByName = (value) => {
  switch (value) {
    case "Draft":
      return 1;
    case "New":
      return 2;
    case "CLOSE":
      return 3;
    default:
      return value;
  }
};
