import { isNullOrUndefined } from "./utils";

export const isEmptyString = (str) => {
  return isNullOrUndefined(str) || str === "" || str === "null" || str === "undefined";
};

export function addPrefix(value, prefix) {
  return `${prefix}${value}`;
}

export function removePrefix(value, prefix) {
  return value ? value.replace(prefix, "") : value;
}

export const splitString = (str, words) => {
  const separator = ",";

  let splitedStr = str;
  words.forEach((word) => {
    splitedStr = splitedStr.split(word).join(separator);
  });

  splitedStr = splitedStr.split(separator);

  return splitedStr;
};

export const isTrue = (val) => {
  const trueExp = ["true", "1"];
  if (isNullOrUndefined(val)) {
    return false;
  } else {
    return val === true || val === 1 || (typeof val === "string" && trueExp.includes(val.toLowerCase()));
  }
};
