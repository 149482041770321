import { redirect } from "react-router-dom";

export function getTokenDuration(){
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const expiresIn = expirationDate.getTime() - now.getTime();
    return expiresIn;
}

export function getAuthToken() {
    const token = localStorage.getItem("token");

    if(!token) {
        return null;
    }

    const tokenDuration = getTokenDuration();

    if (tokenDuration < 0){
        return 'EXPIRED';
    }
    return token;
}

export function tokenLoader() {
    return getAuthToken();
}

export function checkAuthLoader() {
    const token = getAuthToken();
    
    if (!token) {
        return redirect('/login');
    }

    return null;
}
