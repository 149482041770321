import { OzCustomBasicObj } from "../Domain/Common/struct";
import { isNullOrUndefined } from '../Utils/utils';
import { OzTicket } from '../Domain/OzTicket';

export class OzTicketRelation extends OzCustomBasicObj {
  constructor() {
    super();
    this.no = null;
    this.inward_ticket_no = null;
    this.inward_ticket = null;
    this.outward_ticket_no = null;
    this.outward_ticket = null;
    this.inward_to_outward_desc = "";
    this.outward_to_inward_desc = "";
    this.create_acct_no = null;
    this.create_dt = null;
  }

  fromDict(dict) {
    super.fromDict(dict);
    if (!isNullOrUndefined(dict)) {
      this.inward_ticket = new OzTicket().fromDict(dict.inward_ticket);
      this.outward_ticket = new OzTicket().fromDict(dict.outward_ticket);
    }
    return this;
  }
}