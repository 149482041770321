import axios from "axios";
import { GetConfig } from "../Utils/utils"
import { GetConfig1 } from '../Utils/utils'


export const CallWebApi = async (ServiceName, MethodName, payload, config = null, success_action = null, error_action = null) => {
    return await CallWebApi2(ServiceName, MethodName, payload, config)
        .then((res) => {
            // console.log(res)
            if (success_action) {
                // console.log(res)
                return success_action(res)
            }
            return res;
        })
        .catch((err) => {
            // console.log(err.response)
            if (error_action)
                error_action(err)
            return err;
        });
}

export const CallToGodaWebApi = async (ServiceName, MethodName, payload, config = null, success_action = null, error_action = null) => {
  return await CallToGodaWebApi2(ServiceName, MethodName, payload, config)
    .then(res => {
      // console.log(res)
      if (success_action) {
        // console.log(res)
        return success_action(res)
      }
      return res
    })
    .catch(err => {
      // console.log(err.response)
      if (error_action) error_action(err)
      return err
    })
}


export const CallWebApi2 = async (ServiceName, MethodName, payload, config = null) => {
    const REACT_APP_API_URL = (await GetConfig()).REACT_APP_API_URL
    return axios.post(`${REACT_APP_API_URL}${ServiceName}/${MethodName}`, payload, config)

}


export const CallToGodaWebApi2 = async (ServiceName, MethodName, payload, config = null) => {
  const URL = (await GetConfig1()).React_CloudGoda_Admin_Backend_URL
  return axios.post(`${URL}${ServiceName}/${MethodName}`, payload, config)
}

export const CallGodaSSoLoginWebApi= async (token) => {
  const URL = (await GetConfig1()).React_CloudGoda_Portal_Frontend_URL
  window.open(`${URL}#/page/LoginSSO?FlowzaToken=${token}`)
}