import { OzCustomBasicObj } from '../../js/Domain/Common/struct';

export class OzTicketCategory extends OzCustomBasicObj {
    constructor() {
      super();
      this.no = null;
      this.name = "";
      this.project_no = null;
      this.create_dt = null;
      this.update_dt = null;
      this.is_delete = false;
      this.desc = "";
      this.enable_change_ci = false;
      this.enable_relation_ticket = false;
      this.enable_sub_ticket = false;
      this.enable_relation_ci = false;
      this.enable_ticket_sub_name = false;
      this.enable_bind_ticket = false;
    }
}
  