import { produce } from "immer"

const initState = {
  msg_log_by_msg: {},
  errorcode: null,
}

const msgReducer = produce((state = initState, action) => {
  switch (action.type) {
    case 'Error':
      state.errorcode = action.payload ? action.payload : 500;
      return state;
      // return {
      //   ...state,
      //   // tickets: null,
      //   errorcode: action.payload ? action.payload : 500
      // }
    case 'GetMsgLogByMsgNo_SUCCESS':
      state.msg_log_by_msg[action.msg_no] = action.payload
      return state
      // return {
      //   ...state,
      //   msg_log_by_msg: {
      //     ...state.msg_log_by_msg
      //   },
      //   errorcode: null
      // }
    default:
      return state;
  }
});

export default msgReducer