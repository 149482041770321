import { OzApiProvider } from "./api_provider";

export class TicketCustomizeSettingApiProvider extends OzApiProvider {
  getTicketCustomizeSettingsByCategoryNo = async (category_no) => {
    const data = {
      category_no,
      with_relation_data: true,
    };
    return await this._WebApi("ticket/customize/setting", "GetByCategoryNo", data);
  };

  getTicketCustomizeSettingByCategoryNoClassNo = async ({ category_no, class_no, with_cff, with_ticket_class }) => {
    const data = {
      category_no,
      class_no,
      with_cff,
      with_ticket_class,
    };
    return await this._WebApi("ticket/customize/setting", "GetByCategoryNoClassNo", data);
  };

  setTicketCustomizeSetting = async (tcSetting) => {
    return await this._WebApi("ticket/customize/setting", "Set", tcSetting);
  };
}
