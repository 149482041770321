import { OzCustomBasicObj } from "../Domain/Common/struct";
import { isNullOrUndefined } from '../Utils/utils';
import { OzTicketCff, TicketFieldValue, OzTicketCIRelation } from "../Domain/Ticket/struct";
import { OzTicket } from "../Domain/OzTicket";

export class OzTicketTemplate extends OzCustomBasicObj {
  constructor() {
    super();
    this.no = null;
    this.name = null;
    this.desc = null;
    this.project_no = null;
    this.category_no = null;
    this.create_acct_no = null;
    this.create_dt = null;
    this.is_delete = null;
    this.template_ticket = new OzTemplateTicket();
  }

  fromDict(dict) {
    super.fromDict(dict);
    if (!isNullOrUndefined(dict)) {
      this.template_ticket = new OzTemplateTicket().fromDict(dict.template_ticket);
    }

    return this;
  }

  cloneData(ticketTemplate) {
    //複製
    ticketTemplate.no = null
    ticketTemplate.template_ticket = new OzTemplateTicket().cloneData(ticketTemplate.template_ticket);
    return ticketTemplate;
  }
}

export class OzTemplateTicket extends OzCustomBasicObj {
  constructor() {
    super();
    this.no = null;
    this.master_template_ticket_no = null;
    this.name = "";
    this.sub_name = "";
    this.ticket_desc = "";
    this.project_no = null;
    this.category_no = null;
    this.ticket_class_no = null;
    this.urgency_no = null;
    this.impact_no = null;
    this.priority_no = null;
    this.assign_group_no = "";
    this.assign_acct_no = null;
    this.ci_relations = [];
    this.sub_ticket = [];
    this.status_no = null;
    this.edc_project_no = null;
    this.edc_oid = null;
    this.edc_project_name = null;

    this.cff = new OzTicketCff();
    this.field_values = [];
  }

  fromDict(dict) {
    super.fromDict(dict);
    if (!isNullOrUndefined(dict)) {
      this.field_values = (dict.field_values || []).map((fv) => new TicketFieldValue().fromDict(fv));
      this.sub_ticket = (dict.sub_ticket || []).map((st) => new OzTemplateTicket().fromDict(st));
      this.ci_relations = (dict.ci_relations || []).map((tcr) => new OzTicketCIRelation().fromDict(tcr))
    }
    return this;
  }

  toOzTicket() {
    let res = new OzTicket().fromDict(this.toDict());
    return res;
  }

  fromOzTicket = (ticket) => {
    this.fromDict(ticket.toDict());
    return this;
  }

  cloneData(tempTicket) {
    tempTicket.no = null;
    tempTicket.ci_relations.forEach(cr => cr.no = null);
    for (let i = 0; i < tempTicket.field_values.length; i++) {
      tempTicket.field_values[i].no = null;
      tempTicket.field_values[i].ticket_no = null;
      tempTicket.field_values[i].custom_field_value_no = null;
      for (let j = 0; j < tempTicket.field_values[i].options.length; j++) {
        tempTicket.field_values[i].options[j].no = null;
        tempTicket.field_values[i].options[j].custom_field_value_no = null;
      }
    }
    for (let i = 0; i < tempTicket.sub_ticket.length; i++) {
      tempTicket.sub_ticket[i] = new OzTemplateTicket().cloneData(tempTicket.sub_ticket[i]);
    }
    return tempTicket;
  }

}
