import React from 'react'
import OzViewModel from "../Domain/Common/struct";
import { OzTicketTemplate } from "../Domain/OzTicketTemplate";
import { OzSchedule } from "../Domain/schedule_model";
import { isNullOrUndefined } from '../Utils/utils';
import { Translation } from 'react-i18next'

export const TypeTagEnum = Object.freeze({
  Yearly: "Yearly",
  HalfYearly: "HalfYearly",
  Quarterly: "Quarterly",
  Monthly: "Monthly",
  BiMonthly: "BiMonthly",
  Weekly: "Weekly",
  BiWeekly: "BiWeekly",
  Daily: "Daily",
  Once: "Once",
  NotOnce: "NotOnce",
});

export const TypeTagSelectOptions = [
  { displayName: <Translation>{t => t('years')}</Translation>, value: TypeTagEnum.Yearly },
  { displayName: <Translation>{t => t('six-months')}</Translation>, value: TypeTagEnum.HalfYearly },
  { displayName: <Translation>{t => t('quarter')}</Translation>, value: TypeTagEnum.Quarterly },
  { displayName: <Translation>{t => t('month')}</Translation>, value: TypeTagEnum.Monthly },
  { displayName: <Translation>{t => t('bi-monthly')}</Translation>, value: TypeTagEnum.BiMonthly },
  { displayName: <Translation>{t => t('week')}</Translation>, value: TypeTagEnum.Weekly },
  { displayName: <Translation>{t => t('bi-weekly')}</Translation>, value: TypeTagEnum.BiWeekly },
  { displayName: "日", value: TypeTagEnum.Daily },
  { displayName: <Translation>{t => t('appoint')}</Translation>, value: TypeTagEnum.Once },
  { displayName: <Translation>{t => t('not-appoint')}</Translation>, value: TypeTagEnum.NotOnce },
];
export class OzAutoGenTicket extends OzViewModel {
  constructor(
    no = null,
    category_no = null,
    project_no = null,
    schedule_no = null,
    ticket_template_no = null,
    is_delete = false,
    expected_days = null,
    type_tag = null,
    edc_project_id_ticket_fvv = null,
    ticket_template = new OzTicketTemplate(),
    schedule = new OzSchedule()) {
    super();
    this.no = no;
    this.project_no = project_no;
    this.category_no = category_no;
    this.schedule_no = schedule_no;
    this.ticket_template_no = ticket_template_no;
    this.is_delete = is_delete;
    this.expected_days = expected_days;
    this.type_tag = type_tag;
    this.edc_project_id_ticket_fvv = edc_project_id_ticket_fvv;
    this.ticket_template = ticket_template;
    this.schedule = schedule;
  }

  fromDict(dict) {
    super.fromDict(dict);
    if (!isNullOrUndefined(dict)) {
      this.schedule = new OzSchedule().fromDict(dict.schedule);
      this.ticket_template = new OzTicketTemplate().fromDict(dict.ticket_template)
    }

    return this;
  }

  cloneData(agt) {
    //複製腳本，編號相關欄位清空
    agt.no = null;
    agt.project_no = null;
    agt.category_no = null;
    agt.schedule_no = null;
    agt.ticket_template_no = null;
    agt.ticket_template = new OzTicketTemplate().cloneData(agt.ticket_template);
    agt.schedule.no = null;
    agt.schedule.schedule_times.forEach(st => st.no = null);
    return agt;
  }

  // edc
  showTypeTagStr() {
    return TypeTagSelectOptions.find(option => option.value === this.type_tag)?.displayName
  }
}
