import { produce } from "immer";

const initState = {
  company_groups: [],
  project_company_groups: {},
  accts_company_groups: {},
  errorcode: null,
};

const companyGroupReducer = produce((state = initState, action) => {
  switch (action.type) {
    case "GetCompanyGroupByNo_SUCCESS":
      return {
        ...state,
        company_groups: [...state.company_groups, action.companyGroups],
        errorcode: null,
      };
    case "GetCompanyGroupByProjectNo_SUCCESS":
      // let project_company_groups = state.project_company_groups
      state.project_company_groups[action.project_no] = action.companyGroups;
      return state;
      // return {
      //   ...state,
      //   project_company_groups: {
      //     ...state.project_company_groups,
      //   },
      //   errorcode: null,
      // };
    case "GetCompanyGroupByAcctNo_SUCCESS":
      state.accts_company_groups[action.acct_no] = action.companyGroups;
      return state;
    case "GetCompanyGroup_Error":
      // console.log(action)
      state.errorcode = action.companyGroups ? action.companyGroups : 500;
      return state
      // return {
      //   ...state,
      //   errorcode: action.payload ? action.payload : 500,
      // };
    default:
      return state;
  }
});

export default companyGroupReducer;
