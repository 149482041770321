import React from 'react';
import { Link } from 'react-router-dom';
import {Col} from 'react-bootstrap';

const AppMenuDropdown = (props) => {
    const {tl, tlns, submenu, setOpen} = props;
    return(
        submenu.map((sub, index) => {
            return (
                <Col sm={12} md={6} className="AppMenuDropdown">
                    <Link
                        className="text-title"
                        to={sub.path}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {' '}
                        <h5 className="text-title">{tl(tlns, sub.title)}</h5>
                    </Link>
                    <h6 className="text-desc">{tl(tlns, sub.desc)}</h6>
                </Col>
            );
        })
    )
};

export default AppMenuDropdown;
