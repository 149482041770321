const initState = {
  company_group_tree: null,
  parent_no: null,
  errorcode: null,
  pending: false
}

const companyGroupOrgChartReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GetCompanyGroupOrgChartDataByParentNo_SUCCESS':
      return {
        ...state,
        company_group_tree: action.companyGroupTree,
        parent_no: action.parentNo,
        pending: false,
        errorcode: null
      }
    case 'GetCompanyGroupOrgChartDataByParentNo_Error':
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500
      }
    case 'GetCompanyGroupOrgChartDataByParentNo_Pending':
      return {
        ...state,
        pending: true
      }
    default:
      return state;
  }
}

export default companyGroupOrgChartReducer