import React from 'react';
import { tokenLoader } from '../../js/Base/auth';


const OperationExcellent = React.lazy(() => import('../../pages/portal/OperationExcellent/OperationExcellent'));

const CloudSecurity = React.lazy(() => import('../../pages/portal/CloudSecurity/CloudSecurity'));
const CloudPortal = React.lazy(() => import('../../layout/CloudPortal'));
const HomePortal = React.lazy(() => import('../../pages/Portal'));
const DesignAndDeploy = React.lazy(() => import('../../pages/portal/Deploy/DesignAndDeploy'));
const Page404 = React.lazy(() => import('../../pages/Page404'));
const Page500 = React.lazy(() => import('../../pages/Page500'));

const LoginPage = React.lazy(() => import('../../pages/portal/Login/LoginPage'));
const ForgetPwd = React.lazy(() => import('../../pages/portal/ForgetPwd/ForgetPwd'));
const ReSetPwd = React.lazy(() => import('../../pages/portal/ForgetPwd/ReSetPwd'));
const Cloudgoda = React.lazy(() => import('../../pages/portal/Overview/Cloudgoda'));
const AllService = React.lazy(() => import('../../pages/portal/Overview/AllService'));
const Subscription = React.lazy(() => import('../../pages/portal/Overview/Subscription'));

const GodaMall = React.lazy(() => import('../../pages/portal/GodaMall/GodaMall'));
const SkyCloud = React.lazy(() => import('../../pages/portal/GodaMall/MallCompany/SkyCloud'));
const PentiumNetWork = React.lazy(() => import('../../pages/portal/GodaMall/MallCompany/PentiumNetwork'));

const SmartManagement = React.lazy(() => import('../../pages/portal/SmartManagement/SmartManagement'));
const CloudBackup = React.lazy(() => import('../../pages/portal/CloudBackup/CloudBackup'));

const SimplePriceComparison = React.lazy(() =>
    import('../../pages/portal/CloudServiceProviderQuery/SimplePriceComparison/SimplePriceComparison')
);
const VmSpecDetails = React.lazy(() =>
    import('../../pages/portal/CloudServiceProviderQuery/VmSpecDetails/VmSpecDetails')
);

const LatencyTesting = React.lazy(() => import('../../pages/portal/CloudServiceProviderQuery/LatencyTesting'));
const SecurityPosture = React.lazy(() => import('../../pages/portal/CloudSecurity/SecurityPosture'));
const Flowza = React.lazy(() => import('../../pages/portal/CloudSecurity/Flowza'));

//footer contents
const CloudGodaQA = React.lazy(() => import('../../pages/CloudGodaQA'));
const TermsOfService = React.lazy(() => import('../../pages/TermsOfService'));
const PrivacyStatement = React.lazy(() => import('../../pages/PrivacyStatement'));
const ContactUs = React.lazy(() => import('../../pages/ContactUs'));
const AdminLinkCheck = React.lazy(() => import('../../pages/portal/Login/AdminLinkCheck'));

const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        element: <CloudPortal />,
        id: 'root',
        loader: tokenLoader,
        children: [
            {
                path: '/',
                name: 'home',
                element: <HomePortal />,
            },
            {
                path: '/login',
                name: 'login',
                meta: {
                    title: '登入',
                },
                element: <LoginPage />,
            },
            {
                path: '/forget-password',
                name: 'forgotpassword',
                meta: {
                    title: '忘記密碼',
                },
                element: <ForgetPwd />,
            },
            {
                path: '/resetpwd/:no',
                name: 'resetpwd',
                meta: {
                    title: '重設密碼',
                },
                element: <ReSetPwd />,
            },
            {
                path: '/overview/allservice',
                exact: true,
                name: 'allservice',
                element: <AllService />,
            },
            //訂閱方案
            {
                path: '/overview/subscription',
                exact: true,
                name: 'subscription',
                element: <Subscription />,
            },
            //營運優化
            {
                path: '/operation-excellent',
                exact: true,
                name: '',
                element: <OperationExcellent />,
            },
            //易雲商城
            {
                path: '/goda-mall',
                exact: true,
                name: '',
                element: <GodaMall />,
            },
            //騰雲運算
            {
                path: '/sky-cloud',
                exact: true,
                name: '',
                element: <SkyCloud />,
            },
            //奔騰網路
            {
                path: '/pentium-network',
                exact: true,
                name: '',
                element: <PentiumNetWork />,
            },
            //雲端安全
            {
                path: '/cloud-security',
                exact: true,
                name: '',
                element: <CloudSecurity />,
            },
            //智能管理
            {
                path: '/smart-management',
                exact: true,
                name: '',
                element: <SmartManagement />,
            },
            //雲端備援
            {
                path: '/cloud-backup',
                exact: true,
                name: '',
                element: <CloudBackup />,
            },
            //架構設計與部署
            {
                path: '/deploy',
                exact: true,
                name: '',
                element: <DesignAndDeploy />,
            },
            {
                path: '/pricecomparison',
                exact: true,
                name: '',
                element: <SimplePriceComparison />,
            },
            {
                path: '/vm-spec-details',
                exact: true,
                name: '',
                element: <VmSpecDetails />,
            },
            {
                path: '/latency-testing',
                exact: true,
                name: '',
                element: <LatencyTesting />,
            },
            {
                path: '/security-posture',
                exact: true,
                name: '',
                element: <SecurityPosture />,
            },
            {
                path: '/flowza',
                exact: true,
                name: '',
                element: <Flowza />,
            },
            {
                path: '/cloudgoda',
                exact: true,
                name: '',
                element: <Cloudgoda />,
            },
            {
                path: '/page500',
                exact: true,
                name: 'Page500',
                element: <Page500 />,
            },
            {
                path: '/*',
                exact: true,
                name: 'Page404',
                element: <Page404 />,
            },
            //Footer
            {
                path: '/cloudGodaQA',
                exact: true,
                name: 'CloudGodaQA',
                element: <CloudGodaQA />,
            },
            {
                path: '/terms-of-service',
                exact: true,
                name: 'TermsOfService',
                element: <TermsOfService />,
            },
            {
                path: '/privacy-statement',
                exact: true,
                name: 'PrivacyPolicy',
                element: <PrivacyStatement />,
            },
            {
                path: '/contact-us',
                exact: true,
                name: 'ContactUs',
                element: <ContactUs />,
            },

            // 以下都是admin的連結，要設定在portal的route才不會出現404
            //控制台
            {
                path: '/console',
                name: 'admin-console',
                element: <AdminLinkCheck />,
            },
            //資源資產組態-分類清單
            {
                path: '/classification-list',
                name: 'admin-classification-list',
                element: <AdminLinkCheck />,
            }, //監控儀錶板-AZURE效能
            {
                path: '/azure-performance',
                name: 'admin-azure-performance',
                element: <AdminLinkCheck />,
            }, //監控儀錶板-AZURE效能內容
            {
                path: '/performance-content',
                name: 'admin-performance-content',
                element: <AdminLinkCheck />,
            },
            //資源資產組態
            {
                path: '/resource-asset-configuration',
                name: 'admin-resource-asset-configuration',
                element: <AdminLinkCheck />,
            },
            // 安全性建議
            {
                path: '/cloud/security',
                exact: true,
                name: 'admin-best-security',
                element: <AdminLinkCheck />,
            },
            // Flowza
            {
                path: '/my-todo-list',
                exact: true,
                name: 'admin-my-todo-list',
                element: <AdminLinkCheck />,
            },
            {
                path: '/incident',
                exact: true,
                name: 'admin-incident',
                element: <AdminLinkCheck />,
            },
            {
                path: '/ticket/:no',
                exact: true,
                name: 'admin-ticket',
                element: <AdminLinkCheck />,
            },
            {
                path: '/soc-dashboard',
                exact: true,
                name: 'admin-soc-dashboard',
                element: <AdminLinkCheck />,
            },
            {
                path: '/soc-report',
                exact: true,
                name: 'admin-soc-report',
                element: <AdminLinkCheck />,
            },
            {
                path: '/report-download',
                exact: true,
                name: 'admin-report-download',
                element: <AdminLinkCheck />,
            },
            {
                path: '/search-history-ticket',
                exact: true,
                name: 'admin-search-history-ticket',
                element: <AdminLinkCheck />,
            },
            // 班表管理
            {
                path: '/employee_schedule',
                exact: true,
                name: 'admin-employee_schedule',
                element: <AdminLinkCheck />,
            },
            {
                path: '/pending-ticket-user-ranking',
                exact: true,
                name: 'admin-pending-ticket-user-ranking',
                element: <AdminLinkCheck />,
            },
            {
                path: '/pending-ticket-ip-ranking',
                exact: true,
                name: 'admin-pending-ticket-ip-ranking',
                element: <AdminLinkCheck />,
            },
            // 安全性態勢
            {
                path: '/cloud/security/securityPosture',
                exact: true,
                name: 'admin-cloud-security-posture',
                element: <AdminLinkCheck />,
            },
            // 安全分數建議
            {
                path: '/cloud/security/securityScoreRecommendations',
                exact: true,
                name: 'admin-security-score-recommendation',
                element: <AdminLinkCheck />,
            },
            // 法規合規性
            {
                path: '/cloud/regulatoryCompliance',
                exact: true,
                name: 'admin-regulatory-compliance',
                element: <AdminLinkCheck />,
            },
            // 智能管理
            {
                path: '/azure-cost-dashboard',
                exact: true,
                name: 'admin-azure-cost-dashboard',
                element: <AdminLinkCheck />,
            },
            {
                path: '/classificationCost',
                exact: true,
                name: 'admin-classification-cost',
                element: <AdminLinkCheck />,
            },
            {
                path: '/best/cost',
                exact: true,
                name: 'admin-best-cost',
                element: <AdminLinkCheck />,
            },
            {
                path: '/best/reliabilityRecommendations',
                exact: true,
                name: 'admin-reliability-recommendation',
                element: <AdminLinkCheck />,
            },
            {
                path: '/best/operationalRecommendations',
                exact: true,
                name: '',
                element: <AdminLinkCheck />,
            },
            {
                path: '/best/performanceRecommendations',
                exact: true,
                name: 'admin-best-performance',
                element: <AdminLinkCheck />,
            },
            // 營運優化
            // 作⽤中的服務問題事件
            {
                path: '/best/operationalExcellence/activeServiceIssue',
                exact: true,
                name: 'admin-active-service-issus',
                element: <AdminLinkCheck />,
            },
            // 作⽤中的計劃性維護事件
            {
                path: '/best/operationalExcellence/activePlannedMaintenance',
                exact: true,
                name: 'admin-active-planned-maintenance',
                element: <AdminLinkCheck />,
            },
            // Azure 狀態
            {
                path: '/best/operationalExcellence/azureStatus',
                exact: true,
                name: 'admin-azure-status',
                element: <AdminLinkCheck />,
            },
            // 動作群組
            {
                path: '/best/operationalExcellence/actionGroup',
                exact: true,
                name: 'admin-action-group',
                element: <AdminLinkCheck />,
            },
            // 健康狀態警⽰
            {
                path: '/best/operationalExcellence/healthAlert',
                exact: true,
                name: 'admin-health-alert',
                element: <AdminLinkCheck />,
            },
            // 備份備援
            {
                path: '/job-overview',
                exact: true,
                name: 'admin-job-overview',
                element: <AdminLinkCheck />,
            },
            {
                path: '/backup-infrastructure',
                exact: true,
                name: 'admin-backup-infrastructure',
                element: <AdminLinkCheck />,
            },
            {
                path: '/backup-traffic',
                exact: true,
                name: 'admin-backup-traffic',
                element: <AdminLinkCheck />,
            },
            //系統設定
            //流程管理
            {
                path: '/project/process-management',
                exact: true,
                name: 'admin-process-management',
                element: <AdminLinkCheck />,
            },
        ],
    },
];

export default routes;
