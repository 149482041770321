import { _WebApi } from '../../Service/Ticket/api'
import { CmdbTempCI } from '../../Domain/CMDB/struct';
import { OzAutoGenTicket } from '../../Domain/OzAutoGenTicket';
import { OzTicketRelation } from '../../Domain/OzTicketRelation';
import { OzTicket } from '../../Domain/OzTicket';
import { OzMailContent } from '../../Domain/OzMailContent';
import { OzNocReplyMailPeopleGroup } from '../../Domain/OzNocReplyMailPeopleGroup'
import { OzReplyMailTemplate } from '../../Domain/OzReplyMailTemplate'


export function findTicketCategoryByNo(categoryNo) {
  return _WebApi("ticket/setting", "FindTicketCategoryByNo", { category_no: categoryNo })
}

export function findTicketCategoryByProjectNo(projectNo) {
  return _WebApi("ticket/setting", "FindTicketCategoryByProjectNo", { project_no: projectNo });
}

export function createTicketCategory(name, desc, project_no) {
  const data = {
    ticket_category: {
      name: name,
      desc: desc,
      project_no: project_no
    }
  }
  return _WebApi("ticket/setting", "CreateTicketCategory", data);
}

export function editTicketCategory(category) {
  return _WebApi("ticket/setting", "EditTicketCategory", { ticket_category: category });
}

export function findTicketStatus(ticketCategoryNo) {
  return _WebApi("ticket/setting", "FindTicketStatusByTicketCategoryNo", { ticket_category_no: ticketCategoryNo });
}

export function createTicketStatus(name, sort, ticketCategoryNo, projectNo) {
  const data = {
    ticket_status: {
      name: name,
      sort: sort
    },
    ticket_category_no: ticketCategoryNo,
    project_no: projectNo
  }
  return _WebApi("ticket/setting", "CreateTicketStatus", data);
}

export function editTicketStatuses(ticketStatuses, ticketCategoryNo) {
  const data = {
    ticket_status_list: ticketStatuses,
    ticket_category_no: ticketCategoryNo
  }
  return _WebApi("ticket/setting", "EditTicketStatusesByCategoryNo", data);
}

export function findTicketCategorySerialNumberByCategoryNo(ticketCategoryNo) {
  return _WebApi("ticket/setting", "FindTicketCategorySerialNumberByCategoryNo", { ticket_category_no: ticketCategoryNo });
}


export function createTicketCategorySerialNumber(code, newDate, digits, returnTo1Type, ticketCategoryNo, projectNo) {
  const data = {
    ticket_category_serial_num: {
      code: code,
      use_date: newDate,
      digits: digits,
      return_to_1_type: returnTo1Type,
      category_no: ticketCategoryNo,
      project_no: projectNo
    }
  }

  return _WebApi("ticket/setting", "CreateTicketCategorySerialNumber", data);
}

export function editTicketCategorySerialNumber(ticketCategorySerialNum) {
  const data = {
    ticket_category_serial_num: ticketCategorySerialNum
  }
  return _WebApi("ticket/setting", "EditTicketCategorySerialNumber", data);
}

export function findTicketClassByParentNo(ticketCategoryNo, parentNo) {
  const data = {
    ticket_category_no: ticketCategoryNo,
    parent_no: parentNo
  }
  return _WebApi("ticket/setting", "FindTicketClassByParentNo", data);
}

export function createTicketClass(ticketClass) {
  return _WebApi("ticket/setting", "CreateTicketClass", { ticket_class: ticketClass });
}

export function editTicketClass(ticket_class) {
  return _WebApi("ticket/setting", "EditTicketClass", { ticket_class: ticket_class });
}

export function findTicketCategoryLevelByCategoryNo(ticketCategoryNo) {
  return _WebApi("ticket/setting", "FindTicketCategoryLevelByCategoryNo", { category_no: ticketCategoryNo });
}

export function editTicketCategoryLevels(ticketCategoryLevelList, ticketCategoryNo) {
  const data = {
    ticket_category_level_list: ticketCategoryLevelList,
    ticket_category_no: ticketCategoryNo
  };
  return _WebApi("ticket/setting", "EditTicketCategoryLevels", data);
}

export function findTicketClassAndLinealParentsByNo(ticket_class_no) {
  return _WebApi("ticket/setting", "FindTicketClassAndLinealParentsByNo", { ticket_class_no: ticket_class_no });
}

export async function findTicketRelationByTicketNo(ticket_no) {
  let res = [];
  const relations = await _WebApi("ticket", "FindTicketRelationByTicketNo", { ticket_no: ticket_no }).then(res => res.data.data);
  relations.forEach(relation => res.push(new OzTicketRelation().fromDict(relation)));
  return res;
}

export function createTicketRelations(ticket_relations) {
  return _WebApi("ticket", "CreateTicketRelations", { ticket_relations: ticket_relations });
}

export function updateTicketRelations(ticket_relations) {
  return _WebApi("ticket", "UpdateTicketRelations", { ticket_relations: ticket_relations });
}

export function editTicketRelation(ticket_relation) {
  return _WebApi("ticket", "EditTicketRelation", { ticket_relation: ticket_relation });
}

export function deleteTicketRelaion(ticket_relation_no) {
  return _WebApi("ticket", "DeleteTicketRelation", { ticket_relation_no: ticket_relation_no });
}

export function findTicketClassByCategoryNo(ticket_category_no) {
  return _WebApi("ticket/setting", "FindTicketClassByCategoryNo", { ticket_category_no: ticket_category_no });
}

export function findTicketCIRelationsByTicketNo(ticket_no) {
  return _WebApi("ticket", "FindTicketCIRelationsByTicketNo", { ticket_no: ticket_no });
}

export function createTicketCIRelations(ticket_ci_relations) {
  return _WebApi("ticket", "CreateTicketCIRelations", { ticket_ci_relations: ticket_ci_relations });
}

export function editTicketCIRelation(ticket_ci_relation) {
  return _WebApi("ticket", "EditTicketCIRelation", { ticket_ci_relation: ticket_ci_relation });
}

export function deleteTicketCIRelation(ticket_ci_relation_no) {
  return _WebApi("ticket", "DeleteTicketCIRelation", { ticket_ci_relation_no: ticket_ci_relation_no });
}

export function updateTicketCIRelations(ticket_ci_relations) {
  return _WebApi("ticket", "UpdateTicketCIRelations", { ticket_ci_relations: ticket_ci_relations });
}

export function createTicketChangeCIRelations(ticket_change_ci_relations) {
  return _WebApi("ticket", "CreateTicketChangeCIRelations", { ticket_change_ci_relations: ticket_change_ci_relations });
}

export function deleteTicketChangeCIRelation(ticket_change_ci_relation_no) {
  return _WebApi("ticket", "DeleteTicketChangeCIRelation", { ticket_change_ci_relation_no: ticket_change_ci_relation_no });
}

export function findTicketChangeCIRelationsByTicketNo(ticket_no) {
  return _WebApi("ticket", "FindTicketChangeCIRelationsByTicketNo", { ticket_no: ticket_no });
}

export async function findTempCIByTicketChangeCIRelationNo(ticket_change_ci_relation_no) {
  const data = {
    ticket_change_ci_relation_no: ticket_change_ci_relation_no
  };
  const tempCIDict = await _WebApi("ticket", "FindTempCIByTicketChangeCIRelationNo", data).then((res) => res.data.data);
  const temp_ci =  new CmdbTempCI(
    tempCIDict.no,
    tempCIDict.company_no,
    tempCIDict.class_no,
    tempCIDict.name,
    tempCIDict.enable,
    tempCIDict.description,
    tempCIDict.memo,
    tempCIDict.version,
    tempCIDict.create_time,
    tempCIDict.update_time,
    tempCIDict.field_values,
    tempCIDict.original_instance_no,
    tempCIDict.related_ticket_no,
    tempCIDict.cmdb_class,
    tempCIDict.relations,
  );
  return temp_ci
}

export async function copyMasterTicketChangeCIRelation(ticket_no) {
  return _WebApi("ticket", "CopyMasterTicketChangeCIRelation", { ticket_no: ticket_no });
}

export function createAutoGenTicket(autoGenTicket) {
  return _WebApi("auto_gen_ticket", "Create", { auto_gen_ticket: autoGenTicket });
}

export function updateAutoGenTicket(autoGenTicket) {
  return _WebApi("auto_gen_ticket", "Edit", { auto_gen_ticket: autoGenTicket.toDict() });
}

export async function findAutoGenTicketByNo(no) {
  const data = {
    no: no
  };
  const res = await _WebApi("auto_gen_ticket", "FindByNo", data).then((res) => res.data.data);
  return { data: { data: new OzAutoGenTicket().fromDict(res) } }
}

export async function findAutoGenTicketByCategoryNo(categoryNo, page, pageSize, search, edc_order_by_fields) {
  let autoGenTickets = [];
  const data = {
    search: search,
    order_by_fields: edc_order_by_fields,
    category_no: categoryNo,
    page: page,
    page_size: pageSize
  }
  const page_data = await _WebApi("auto_gen_ticket", "FindByCategoryNo", data).then((res) => res.data.data);
  page_data.items.forEach((item) => autoGenTickets.push(new OzAutoGenTicket().fromDict(item)))
  return { items: autoGenTickets, total: page_data.total }
}

export async function findCreatedTicketByAutoGenTicketNo(autoGenTicketNo, page, pageSize, order_by_fields) {
  let tickets = [];
  const data = {
    no: autoGenTicketNo,
    order_by_fields: order_by_fields,
    page: page,
    page_size: pageSize,
  }
  const page_data = await _WebApi("auto_gen_ticket", "FindCreatedTicketsByNo", data).then((res) => res.data.data);
  page_data.items.forEach((item) => tickets.push(new OzTicket().fromDict(item)))
  return { items: tickets, total: page_data.total }
}

export function deleteAutoGenTicket(no) {
  const errorState = (code) => {
    switch (code) {
      case 'auth_err':
        return "您沒有刪除排程單的權限";
      default:
        return null;
    }
  }
  return _WebApi("auto_gen_ticket", "Delete", { no: no }, errorState);
}

export function genTicket(auto_gen_ticket_no) {
  return _WebApi("auto_gen_ticket", "GenTicketByNo", { auto_gen_ticket_no: auto_gen_ticket_no });
}

export async function findMailContentByTicketNo(ticket_no) {
  const data = {
    ticket_no: ticket_no
  };
  const tempMailContentDict = await _WebApi("ticket", "FindMailContentByTicketNo", data).then((res) => res.data.data);
  let oz_mail_content = new OzMailContent();
  if(tempMailContentDict != null){
    oz_mail_content.no = tempMailContentDict.no;
    oz_mail_content.from_mail = tempMailContentDict.from_mail;
    oz_mail_content.subject = tempMailContentDict.subject;
    oz_mail_content.text_body = tempMailContentDict.text_body;
    oz_mail_content.flowza_ticket_no = tempMailContentDict.flowza_ticket_no;
    oz_mail_content.is_task_check = tempMailContentDict.is_task_check;
    oz_mail_content.is_task_check = tempMailContentDict.is_incident_check;
    oz_mail_content.cc = tempMailContentDict.cc;
  }
  return oz_mail_content;
}

export async function createNocReplyMail(ticket_no, sender, cc, subject, content) {
  const data = {
    ticket_no: ticket_no,
    sender: sender,
    cc: cc,
    subject: subject,
    content: content,
  };
  return _WebApi("ticket", "NotifyMember", data);
}

export async function findCIProjectAndPeopleByOID(oid) {
  const data = {
    oid: oid
  };
  const tempDict = await _WebApi("cmdb/ci", "GetCIProjectAndPeopleByCompanyOID", data).then((res) => res.data.data);
  let peoplegroups = []
  
  if(tempDict != null){
    for (let i = 0; i < tempDict.length; i++){
      let peoplegroup = new OzNocReplyMailPeopleGroup();
      peoplegroup.ci_no=tempDict[i].ci_no;
      peoplegroup.class_no=tempDict[i].class_no;
      peoplegroup.name=tempDict[i].name;
      peoplegroup.custom_field=tempDict[i].custom_field;
      peoplegroups.push(peoplegroup)
    }
  }
  return peoplegroups;
}

export async function findReplyMailTemplate(enable){
  const data = {
    enable: enable
  };
  const tempDict = await _WebApi("ticket", "FindMailTemplate", data).then((res) => res.data.data);
  let template_list = []

  if(tempDict != null){
    for(let i = 0; i < tempDict.length; i++){
      let template = new OzReplyMailTemplate();
      template.no=tempDict[i].no
      template.template_type=tempDict[i].template_type
      template.name=tempDict[i].name
      template.value=tempDict[i].value
      template.enable=tempDict[i].enable
      template.lang=tempDict[i].lang
      template.create_dt=tempDict[i].create_dt
      template.update_dt=tempDict[i].update_dt
      template_list.push(template)
    }
  }
  return template_list
}

export async function setReplyMailTemplate(no, name, enable, content, is_update) {
  const data = {
    no: is_update ? no : 0,
    name: name,
    enable: enable,
    content: content,
  };
  return _WebApi("ticket", "SetMailTemplate", data);
}

export async function createTicketProcess(ticket_no, group_ticket_no, new_process) {
  const ticket_process= {
    ticket_no: ticket_no,
    group_ticket_no: group_ticket_no,
    process_desc: new_process
  }
  const data = {
    ticket_process: ticket_process,
  }
  return _WebApi("ticket", "CreateTicketProcess", data);
}