const initState = {
    rules: [],
    errorcode: null,
    update_rule_by_file: null,
}

const sourceSecurityRuleReducer = (state = initState, action) => {

    switch (action.type) {
      case 'Error':
        return {
          errorcode: action.payload ? action.payload : 500,
        }
      case 'SourceSecurityRuleList_SUCCESS':
        return {
          ...state,
          rules: action.payload,
          errorcode: null,
        }
      case 'UpdateSourceSecurityRuleByFile_SUCCESS':
        return {
          ...state,
          update_rule_by_file: action.payload,
          errorcode: null,
        }
      case 'UpdateSourceSecurityRule_SUCCESS':
        return {
          ...state,
          rules: action.payload,
          errorcode: null,
        }
      case 'CreateSourceSecurityRule_SUCCESS':
        return {
          ...state,
          rules: action.payload,
          errorcode: null,
        }
      case 'FindSourceSecurityRuleByIdAndProvider_SUCCESS':
        return {
          ...state,
          rules: action.payload,
          errorcode: null,
        }
      default:
        return state
    }
}
export default sourceSecurityRuleReducer