import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { generateUUID, CFG_ENABLE_CMDB, CFG_ENABLE_NOTIFY, CLOSE_Sentinel_INCIDENT, CHECK_TICKET_QUANTITY, isNullOrUndefined } from '../../../js/Utils/utils'
import { addPrefix, removePrefix } from '../../../js/Utils/string'
import { FIELD_PREFIX, REF_ITEM_PREFIX, InputBoxType, TextType, CI_NO_PREFIX } from '../../../js/Domain/CMDB/utils'

// import CustomNotification from '../../../views/Component/Common/Notification'
import { ArrayIsEmptyError, ArrayHasMoreThanOneElementError } from '../../../js/Domain/OzError'
import { Translation } from 'react-i18next'
import i18n from "i18next";
export default class OzViewModel {
  constructor() {
    this.uuid = generateUUID()
  }

  fromDict(objDict) {
    if (isNullOrUndefined(objDict)) {
      return this
    }

    const keys = Object.keys(objDict)
    keys
      .filter(key => this.hasOwnProperty(key))
      .forEach(key => {
        this[key] = objDict[key]
      })

    return this
  }

  toDict() {
    return JSON.parse(JSON.stringify(this, this._Set_toJSONReplacer))
  }

  _Set_toJSONReplacer(key, value) {
    if (typeof value === 'object' && value instanceof Set) {
      return [...value]
    }
    return value
  }
  static fromDict(objDict) {
    return new OzViewModel().fromDict(objDict)
  }

  cloneDeep() {
    let new_obj = _.cloneDeep(this)
    new_obj.uuid = generateUUID()
    return new_obj
  }
}

export class OzCustomBasicClass extends OzViewModel {}

export class OzFieldGroup extends OzViewModel {
  constructor(no = null, name = '', enable = true, deleted = false, order = null, fields = []) {
    super()

    this.no = no
    this.name = name
    this.enable = enable
    this.deleted = deleted
    this.order = order

    this.fields = fields
      .map(field => new CustomField().fromDict(field))
      .filter(field => isNullOrUndefined(field.parent_no))
      .sort((a, b) => a.order - b.order)
  }

  fromDict(fgDict) {
    super.fromDict(fgDict)

    if (!isNullOrUndefined(fgDict)) {
      this.fields = (fgDict.fields || [])
        .map(field => new CustomField().fromDict(field))
        .filter(field => isNullOrUndefined(field.parent_no))
        .sort((a, b) => a.order - b.order)
    }

    return this
  }

  getFields = (flatSubField = false) => {
    let fields = this.fields
    if (flatSubField) {
      fields = this.fields.map(field => [field, ...field.sub_fields]).flat()
    }
    return fields
  }

  addNullItemInField = () => {
    this.fields.forEach(field => field.addNullItem())
  }

  generateInitialState = () => {
    const initialValue = {}

    this.fields.forEach(field => {
      const cmdbFieldName = addPrefix(field.no, FIELD_PREFIX)

      let errMsg = ''
      switch (InputBoxType.Enum[field.input_box_type]) {
        case InputBoxType.Enum.TextBox:
        case InputBoxType.Enum.TextArea:
          // 若是輸入框，設定預設值
          initialValue[cmdbFieldName] = ''
          break

        case InputBoxType.Enum.DatePicker:
        case InputBoxType.Enum.DateTimePicker:
          initialValue[cmdbFieldName] = null
          break

        case InputBoxType.Enum.ComboBox:
        case InputBoxType.Enum.DropDown:
          // 若是選項框，預設點選第一項
          if (field.items.length > 0) {
            const itemName = addPrefix(field.items[0].no, REF_ITEM_PREFIX)
            initialValue[cmdbFieldName] = itemName
          }
          break

        case InputBoxType.Enum.CheckBox:
          initialValue[cmdbFieldName] = []
          break

        case InputBoxType.Enum.MultiLevelDropDown:
          const rootItems = field.items.filter(item => isNullOrUndefined(item.parent_no))
          if (rootItems.length === 0) {
            errMsg = `找不到 根 item，所有 item 的 parent_no 都不為 null，欄位名稱: ${field.name}`
            // CustomNotification.error(errMsg)
          }
          const firstRootItem = rootItems[0]
          const defaultItem = firstRootItem.getDeepestItem()
          const itemName = addPrefix(defaultItem.no, REF_ITEM_PREFIX)
          initialValue[cmdbFieldName] = itemName
          break

        case InputBoxType.Enum.Table:
          // table 設為 null
          initialValue[cmdbFieldName] = null

          // column 設為空陣列
          field.sub_fields.forEach(subField => {
            const subFieldName = subField.getFormikName()
            initialValue[subFieldName] = []
          })
          break

        case InputBoxType.Enum.TableColumn:
          errMsg = `Table column 應先被過濾掉 field no: ${field.no} field name: ${field.name}`
          // CustomNotification.warn(errMsg)
          initialValue[cmdbFieldName] = null
          break

        case InputBoxType.Enum.Relationship:
          initialValue[cmdbFieldName] = null
          break

        default:
          errMsg = `InputBoxType ${i18n.t('unrecognized', { 'unrecognized': 'unrecognized' })}: ${field.input_box_type}`
          // CustomNotification.error(errMsg)
          throw new Error(errMsg)
      }
    })

    return initialValue
  }
}

export class CustomField extends OzViewModel {
  constructor(
    no = null,
    field_group_no = null,
    name = '',
    enable = true,
    deleted = false,
    essential = false,
    text_type = TextType.getKeyByValue(TextType.Enum.Text),
    input_box_type = InputBoxType.getKeyByValue(InputBoxType.Enum.TextBox),
    order = null,
    relate_ci_type_no = null,
    parent_no = null,
    sub_fields = [],
    items = []
  ) {
    super()
    this.no = no
    this.field_group_no = field_group_no
    this.name = name
    this.enable = enable
    this.deleted = deleted
    this.essential = essential
    this.text_type = text_type
    this.input_box_type = input_box_type
    this.order = order
    this.relate_ci_type_no = relate_ci_type_no
    this.parent_no = parent_no
    this.sub_fields = sub_fields.map(subField => new CustomField().fromDict(subField))

    this.items = items
      .map(item => new CustomFieldItem().fromDict(item))
      .filter(item => isNullOrUndefined(item.parent_no))
      .sort((a, b) => a.order - b.order)
  }

  fromDict(fieldDict) {
    super.fromDict(fieldDict)

    if (!isNullOrUndefined(fieldDict)) {
      this.sub_fields = (fieldDict.sub_fields || []).map(subField => new CustomField().fromDict(subField))

      this.items = (fieldDict.items || [])
        .map(item => new CustomFieldItem().fromDict(item))
        .filter(item => isNullOrUndefined(item.parent_no))
        .sort((a, b) => a.order - b.order)
    }

    return this
  }

  filterDisable = () => {
    this.items = this.items.filter(item => item.enable)

    // 過濾 item 的 sub_items
    this.items.forEach(item => item.filterDisable())
  }

  filterDelete = () => {
    this.items = this.items.filter(item => !item.deleted)

    // 過濾 item 的 sub_items
    this.items.forEach(item => item.filterDelete())
  }

  getFormikName = () => {
    return addPrefix(this.no, FIELD_PREFIX)
  }

  getItemPathString = (item, conj) => {
    const rootItems = this.getRootItems()

    // 為每個 item 設定 parent
    rootItems.forEach(rootItem => rootItem.children.forEach(subItem => subItem.recursivelySetParent(rootItem)))
    // 找 path
    const path = item.getPathString(conj)
    // 移除 parent
    rootItems.forEach(rootItem => rootItem.children.forEach(subItem => subItem.recursivelyDeleteParent(rootItem)))

    return path
  }

  findItemByNo = itemNo => {
    itemNo = parseInt(itemNo)
    let targetItem = this.items.find(item => item.enable && !item.deleted && item.no === parseInt(itemNo))

    // 若在 root items 中找不到，用 dfs 找 sub item
    if (isNullOrUndefined(targetItem)) {
      for (let i = 0; i < this.items.length; i++) {
        const subItem = this.items[i]
        targetItem = subItem.dfsGetSubItemByNo(itemNo)
        // 找到第一個就 return
        if (targetItem) {
          return targetItem
        }
      }
    }

    return targetItem
  }

  findItemByName = name => {
    let targetItem = this.items.find(item => item.enable && !item.deleted && item.value === name)

    // 若在 root items 中找不到，用 dfs 找 sub item
    if (isNullOrUndefined(targetItem)) {
      for (let i = 0; i < this.items.length; i++) {
        const subItem = this.items[i]
        targetItem = subItem.dfsGetSubItemByName(name)
        // 找到第一個就 return
        if (targetItem) {
          return targetItem
        }
      }
    }

    return targetItem
  }

  getRootItems = () => {
    return this.items.filter(item => isNullOrUndefined(item.parent_no))
  }

  addNullItem = () => {
    const NULL_ITEM_NAME = <Translation>{t => t('not-appoint')}</Translation>
    const hadNullItem = this.items[0]?.value === NULL_ITEM_NAME
    if (hadNullItem) {
      return
    }

    switch (InputBoxType.Enum[this.input_box_type]) {
      case InputBoxType.Enum.DropDown:
      case InputBoxType.Enum.ComboBox:
      case InputBoxType.Enum.MultiLevelDropDown:
        const newItem = new CustomFieldItem()
        newItem.value = NULL_ITEM_NAME
        this.items = [newItem, ...this.items]

        break

      default:
        break
    }
  }
}

export class CustomFieldItem extends OzViewModel {
  constructor(no = null, field_no = null, enable = true, deleted = false, value = '', order = null, parent_no = null, children = []) {
    super()
    this.no = no
    this.field_no = field_no
    this.enable = enable
    this.deleted = deleted
    this.value = value
    this.order = order
    this.parent_no = parent_no
    this.children = children.map(subItem => new CustomFieldItem().fromDict(subItem)).sort((a, b) => a.order - b.order)

    // for react-sortable-tree
    this.uuid = generateUUID()
    this.expanded = true
  }

  fromDict(itemDict) {
    super.fromDict(itemDict)

    if (!isNullOrUndefined(itemDict)) {
      this.children = (itemDict.children || []).map(subItem => new CustomFieldItem().fromDict(subItem)).sort((a, b) => a.order - b.order)
    }

    return this
  }

  getFormikName = () => {
    return addPrefix(this.no, REF_ITEM_PREFIX)
  }

  filterDisable = () => {
    // 若選項已經停用，所有子選項也要停用
    if (!this.enable) {
      this.children.forEach(child => child.recursivelySetDisable())
    }

    // 遞迴從葉節點過濾 children
    this.children.forEach(child => child.filterDisable())
    this.children = this.children.filter(subItem => subItem.enable)
  }

  filterDelete = () => {
    // 若選項已經刪除，所有子選項也要刪除
    if (this.deleted) {
      this.children.forEach(child => child.recursivelySetDelete())
    }

    // 遞迴從葉節點過濾 children
    this.children.forEach(child => child.filterDelete())
    this.children = this.children.filter(subItem => !subItem.deleted)
  }

  recursivelySetDisable = () => {
    this.enable = false
    this.children.forEach(child => child.recursivelySetDisable())
  }

  recursivelySetDelete = () => {
    this.deleted = true
    this.children.forEach(child => child.recursivelySetDelete())
  }

  recursivelySetParent = parentItem => {
    this.parent = parentItem
    this.children.forEach(subItem => subItem.recursivelySetParent(this))
  }

  recursivelyDeleteParent = () => {
    this.children.forEach(subItem => subItem.recursivelyDeleteParent())
    delete this.parent
  }

  dfsGetSubItemByNo = itemNo => {
    if (this.no === parseInt(itemNo)) {
      return this
    } else {
      for (let i = 0; i < this.children.length; i++) {
        const subItem = this.children[i]
        const item = subItem.dfsGetSubItemByNo(itemNo)
        if (item) {
          return item
        }
      }
    }
  }

  dfsGetSubItemByName = name => {
    if (this.value === name) {
      return this
    } else {
      for (let i = 0; i < this.children.length; i++) {
        const subItem = this.children[i]
        const item = subItem.dfsGetSubItemByName(name)
        if (item) {
          return item
        }
      }
    }
  }

  getPathString = conj => {
    let path = this.value
    let parent = this.parent
    while (parent) {
      const name = parent.value
      path = `${name}${conj}${path}`

      parent = parent.parent
    }
    return path
  }

  getDeepestItem = () => {
    if (this.children.length > 0) {
      return this.children[0].getDeepestItem()
    } else {
      return this
    }
  }
}

export class OzCustomBasicObj extends OzViewModel {
  mergeObject(obj) {
    Object.keys(obj).forEach(i => (this[i] = obj[i]))
  }

  findFieldValueByFieldNo(fieldNo) {
    const ciFvs = this.findFieldValuesByFieldNo(fieldNo)
    if (ciFvs.length === 0) {
      return null
    } else if (ciFvs.length > 1) {
      throw new Error('CI Field Value more than one')
    }
    return ciFvs[0]
  }

  findFieldValuesByFieldNo(fieldNo) {
    return this.field_values.filter(fv => fv.field_no === fieldNo)
  }

  findFieldValueByCustomFvNo = cusFvNo => {
    return this.field_values.find(fv => fv.custom_field_value_no === cusFvNo)
  }

  checkCIRelation(relations) {
    if (relations.length === 0) {
      throw new ArrayIsEmptyError()
    } else if (relations.length > 1) {
      throw new ArrayHasMoreThanOneElementError()
    }
  }

  getFormikDefaultValue = () => {
    const initialValue = {
      no: CI_NO_PREFIX,
      name: '',
      enable: true,
      description: '',
      memo: '',
      version: 1,
      create_time: new Date(moment.now()),
      update_time: new Date(moment.now()),
    }

    return initialValue
  }
}

export class CustomFieldValue extends OzViewModel {
  constructor(no = null, value = null, row_no = null, fieldNo = null, field = null, options = []) {
    super()
    this.no = no
    this.value = value
    this.row_no = row_no
    this.field_no = fieldNo
    this.field = null
    if (isNullOrUndefined(field)) {
      this.field = new CustomField().fromDict(field)
    }
    this.options = options.map(option => new CustomFieldOption().fromDict(option))
  }

  fromDict(fvDict) {
    super.fromDict(fvDict)

    if (!isNullOrUndefined(fvDict)) {
      this.field = null
      if (isNullOrUndefined(fvDict.field)) {
        this.field = new CustomField().fromDict(fvDict.field)
      }

      this.options = (fvDict.options || []).map(option => new CustomFieldOption().fromDict(option))
    }

    return this
  }

  setValueOptions(inputValue) {
    // 只會有 options 或 value 其中一種
    // 若是 options，則移除 prefix
    // 若非 options，則儲存到 value

    if (Array.isArray(inputValue)) {
      this.options = inputValue.map(val => ({
        item_no: removePrefix(val, REF_ITEM_PREFIX),
      }))
    } else if ((typeof inputValue === 'string' || inputValue instanceof String) && inputValue.includes(REF_ITEM_PREFIX)) {
      this.options = [new CustomFieldOption(null, this.no, removePrefix(inputValue, REF_ITEM_PREFIX))]
    } else if (inputValue === 'true' || inputValue === true) {
      this.value = true
    } else if (inputValue === 'false' || inputValue === false) {
      this.value = false
    } else {
      this.value = inputValue
    }
  }
}

export class CustomFieldOption extends OzViewModel {
  constructor(no = null, customFieldValueNo = null, itemNo = null) {
    super()
    this.no = no
    this.custom_field_value_no = customFieldValueNo
    this.item_no = itemNo
  }
}

export class OzCompany extends OzViewModel {
  constructor(
    no = null,
    id = '',
    name = '',
    sys_owner_acct_no = null,
    create_dt = null,
    timezone = null,
    begin_working_time = null,
    end_working_time = null,
    status = null,
    create_acct_no = null,
    other_data = [],
    configs = []
  ) {
    super()
    this.no = no
    this.id = id
    this.name = name
    this.sys_owner_acct_no = sys_owner_acct_no
    this.create_dt = create_dt
    this.timezone = timezone
    this.begin_working_time = begin_working_time
    this.end_working_time = end_working_time
    this.status = status
    this.create_acct_no = create_acct_no
    this.other_data = other_data
    this.configs = configs.map(cfg => new OzCompanyConfiguration(cfg.no, cfg.company_no, cfg.name, cfg.value))
  }

  fromDict(companyDict) {
    super.fromDict(companyDict)

    if (!isNullOrUndefined(companyDict)) {
      this.configs = (companyDict.configs || []).map(cfg => new OzCompanyConfiguration(cfg.no, cfg.company_no, cfg.name, cfg.value))
    }

    return this
  }

  getCmdbConfig = () => {
    return this.configs.find(config => config.name === CFG_ENABLE_CMDB)
  }

  getNotifyConfig = () => {
    return this.configs.find(config => config.name === CFG_ENABLE_NOTIFY)
  }

  getCloseIncidentConfig = () => {
    return this.configs.find(config => config.name === CLOSE_Sentinel_INCIDENT)
  }

  getCheck_ticket_quantity = () => {
    return this.configs.find(config => config.name === CHECK_TICKET_QUANTITY)
  }
}

export class OzCompanyConfiguration extends OzViewModel {
  constructor(no = null, company_no = null, name = '', value = '') {
    super()
    this.no = no
    this.company_no = company_no
    this.name = name
    this.value = value
  }
}
