import React from "react";
import { createRoot } from "react-dom/client";
import globalvars from "./configs/globalvars";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import "./style/all.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { thunk } from "redux-thunk";
import rootReducer from "./store/reducers/rootReducer";

import "./i18n";

window.globalvars = globalvars;

const renderApp = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(thunk),
  });

  createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <App />
    </Provider>
  );
};

renderApp();
serviceWorker.unregister();
