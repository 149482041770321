import React, {Fragment, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button, Col} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loginMenuAction } from '../../store/loginMenuSlice';
import { isNullOrUndefined } from "../../js/Utils/utils";

const AppLoginMenuDropdown = (props) => {
    const {tl, tlns, menu, dropdownHeight, setDropdownHeight} = props;
    const selectedSecondMenu = useSelector((state) => state.loginMenu.secondMenu);
    const selectedThirdMenu = useSelector((state) => state.loginMenu.thirdMenu);
    const prevSecondMenu = localStorage.getItem("secondMenu");
    const prevThirdMenu = localStorage.getItem("thirdMenu");
    const dispatch = useDispatch();

    useEffect(()=>{
        let maxLength = 0;
        for(let i=0; i < menu.length; i++){
            if(menu[i].hasOwnProperty("submenu")){
                let authSubmenu = menu[i].submenu.map(m =>{
                    if(!m.hasOwnProperty('show') || (m.show === true)){
                         return m
                    }
                    return null;
                }).filter(m => m !== null);
                if(authSubmenu.length > maxLength){
                    maxLength = authSubmenu.length;
                }
            }
        }

        if(maxLength > 6){
            const height = 280 + (maxLength - 6) * 32;
            setDropdownHeight(`${height}px`);
        }else{
            setDropdownHeight('280px');
        }

    },[menu])

    const setLink = (secondMenu) => {
        dispatch(loginMenuAction.setMenuDropdownByVal(false));
        dispatch(loginMenuAction.setSidebarByVal(false));
        dispatch(loginMenuAction.setSecondMenu(secondMenu));
    }

    const menuItemHandler = (menu, selectedSubMenu) => {
        setMenuList(menu, selectedSubMenu);
        RecentPageHandler(selectedSubMenu.title);
        if(isNullOrUndefined(menu)){
            setLink(selectedSubMenu.title);
        }
    };

    const setMenuList = (menu, selectedSubMenu) => {
        //只有兩層
        if(isNullOrUndefined(menu)){
            dispatch(loginMenuAction.setSecondMenu(selectedSubMenu.title));
            dispatch(loginMenuAction.setThirdMenu(null));
            dispatch(loginMenuAction.setThirdMenuList(null));
        }else{ //有三層
            const filterMenu = menu.submenu.map(m => {
                if (!m.hasOwnProperty('show') || m.show === true) {
                    return m;
                }
                return null; 
            }).filter(m => m !== null);
            dispatch(loginMenuAction.setSecondMenu(menu.title));
            dispatch(loginMenuAction.setThirdMenu(selectedSubMenu.title));
            dispatch(loginMenuAction.setThirdMenuList(filterMenu));
        }
        dispatch(loginMenuAction.setSelectFirstMenu());
    };

    const RecentPageHandler = (title) => {
        const currentTime = new Date().toISOString();
        const page_time = { title: title, time: currentTime };

        let recentPages = localStorage.getItem("recent_page");

        if (recentPages) {
            recentPages = JSON.parse(recentPages);

            // Check if the recentPages length is equal to the limit (10)
            if (recentPages.length === 11) {
                // Remove the last item from the array
                recentPages.pop();
            }

            // Check if the title already exists in the recentPages
            const existingPageIndex = recentPages.findIndex((page) => page.title === title);

            // If the page is already in the list, remove it
            if (existingPageIndex !== -1) {
                recentPages.splice(existingPageIndex, 1);
            }

            // Add the new page to the beginning of the array
            recentPages.unshift(page_time);
        } else {
            recentPages = [page_time];
        }

        localStorage.setItem("recent_page", JSON.stringify(recentPages));
    };

    return(
        <Fragment>
            <Col xs={1} className="menuitem" style={{ marginLeft: "3.2%" }}></Col>
            {menu.map((m, menuIndex) => {
                if (m.hasOwnProperty("submenu")) {
                    //有第三層
                    return (
                        <Col key={`menu-col-${menuIndex}`} xs={2} className="menuitem">
                            <div style={{ paddingLeft: "12px" }}>
                                <Button className="btn-menuitem" variant="link">
                                    {tl(tlns, m.title)}
                                </Button>
                            </div>
                            {m.submenu.map((sub, subIndex) => {
                                //不顯示隱藏的menu
                                if (!sub.hasOwnProperty('show') || ('show' in sub && sub.show === true)) {
                                    return (
                                        <Button key={`submenu-btn-${menuIndex}-${subIndex}`} className="btn-submenuitem" variant="link">
                                            <Link
                                                key={`submenu-link-${menuIndex}-${subIndex}`}
                                                className={`text-submenuitem ${(isNullOrUndefined(selectedThirdMenu) ? prevThirdMenu : selectedThirdMenu) === sub.title && 'selected'}`}
                                                to={sub.path}
                                                onClick={() => menuItemHandler(m, sub)}
                                            >
                                                {tl(tlns, sub.title)}
                                            </Link>
                                        </Button>
                                    );
                                }
                            })}
                        </Col>
                    );
                } else {
                    //沒有第三層時點選直接連結
                    return (
                        <Col key={`menu-col-${menuIndex}`} xs={2} className="menuitem">
                            <Button className={`btn-menuitem1 ${(isNullOrUndefined(selectedSecondMenu) ? prevSecondMenu : selectedSecondMenu) === m.title ? 'selected' : ''}`} variant="link">
                                <Link
                                    key={`menu-link-${menuIndex}`}
                                    className={`text-menuitem1 ${(isNullOrUndefined(selectedSecondMenu) ? prevSecondMenu : selectedSecondMenu) === m.title ? 'selected' : ''}`}
                                    to={m.path}
                                    onClick={() => menuItemHandler(null, m)}
                                >
                                    {tl(tlns, m.title)}
                                </Link>
                            </Button>
                        </Col>
                    );
                }
            })}
        </Fragment>
    )
   
};

export default AppLoginMenuDropdown;
