import { isNullOrUndefined } from "./utils";

export const isEmptyArray = (arr) => {
  return isNullOrUndefined(arr) || (Array.isArray(arr) && arr.length === 0);
};

export const distinct = (arr) => {
  let newArr = [...arr];
  if (Array.isArray(arr)) {
    newArr = [...new Set(arr)];
  }
  return newArr;
};
