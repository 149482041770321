import { OzTicketCff, OzTicketCIRelation, OzTicketCustomizeSetting, TicketFieldValue } from "../../../js/Domain/Ticket/struct";
import { TicketCustomizeSettingApiProvider } from "./ticket_customize_setting_api";
import { findTicketCIRelationsByTicketNo, findTicketClassAndLinealParentsByNo } from "../../../js/Base/OzApi/ticket_api";
import { OzTicketApiProvider } from "../../../js/Service/Ticket/api";

export default class APIHelper {
  static getApiProvider() {
    return new OzTicketApiProvider();
  }

  static getTicketCffApiProvider() {
    return new TicketCustomizeSettingApiProvider();
  }

  // ticket cff
  static getTicketCffByTicketNo = async (params) => {
    const { filterDisable, filterDelete, filterTableColumn, ...restParams } = params;

    const apiProvider = this.getApiProvider();
    const ticketCffDict = await apiProvider.getTicketCffByTicketNo(restParams).then((res) => res.data.data);
    const ticketCff = new OzTicketCff().fromDict(ticketCffDict);

    if (filterDisable) ticketCff.filterDisable();
    if (filterDelete) ticketCff.filterDelete();
    if (filterTableColumn) ticketCff.filterTableColumn();

    return ticketCff;
  };

  static getTicketCffByClassNo = async (params) => {
    const { filterDisable, filterDelete, filterTableColumn, ...restParams } = params;

    const apiProvider = this.getApiProvider();
    const ticketCffDict = await apiProvider.getTicketCffByTicketClassNo(restParams).then((res) => res.data.data);
    const ticketCff = new OzTicketCff().fromDict(ticketCffDict);

    if (filterDisable) ticketCff.filterDisable();
    if (filterDelete) ticketCff.filterDelete();
    if (filterTableColumn) ticketCff.filterTableColumn();

    return ticketCff;
  };

  // ticket field value
  static getTicketFieldValuesByTicketNo = async (params) => {
    const apiProvider = this.getApiProvider();
    const ticketFVDicts = await apiProvider.findTicketFieldValuesByTicketNo(params).then((res) => res.data.data);
    const ticketFVs = ticketFVDicts.map((ticketFVDict) => new TicketFieldValue().fromDict(ticketFVDict));

    return ticketFVs;
  };
  

  // ticket cus setting
  static getTicketCustomizeSettingsByCategoryNo = async (...params) => {
    const apiProvider = this.getTicketCffApiProvider();
    const tcSettingDicts = await apiProvider
      .getTicketCustomizeSettingsByCategoryNo(...params)
      .then((res) => res.data.data);
    const tcSettings = tcSettingDicts.map((tcSettingDict) => new OzTicketCustomizeSetting().fromDict(tcSettingDict));

    return tcSettings;
  };

  static _getTicketCustomizeSettingByCategoryNoClassNo = async ({
    categoryNo,
    classNo,
    with_cff,
    with_ticket_class,

    filterDisable = true,
    filterDelete = true,
    filterTableColumn = true,
  }) => {
    const apiProvider = this.getTicketCffApiProvider();
    const tcSettingDict = await apiProvider
      .getTicketCustomizeSettingByCategoryNoClassNo({
        category_no: categoryNo,
        class_no: classNo,
        with_cff,
        with_ticket_class,
      })
      .then((res) => res.data.data);

    const tcSetting = new OzTicketCustomizeSetting().fromDict(tcSettingDict);
    tcSetting.ticket_category_no = categoryNo;
    tcSetting.ticket_class_no = classNo;

    if (filterDisable) tcSetting.ticket_cff.filterDisable();
    if (filterDelete) tcSetting.ticket_cff.filterDelete();
    if (filterTableColumn) tcSetting.ticket_cff.filterTableColumn();

    return tcSetting;
  };

  static getTicketCustomizeSettingByCategoryNoClassNo = async ({ categoryNo, classNo, ...restProps }) => {
    // 取得所有線性父類別的 ticket classes
    // ex: f(e) = a, b, e
    //      a
    //    /   \
    //   b     c
    //  / \   /
    // d   e f
    let ticketClasses = await findTicketClassAndLinealParentsByNo(classNo).then((res) => res.data.data);
    ticketClasses = ticketClasses.reverse();

    // get tcSettings

    const tcSettingPromises = ticketClasses.map(
      async (ticketClass) =>
        await this._getTicketCustomizeSettingByCategoryNoClassNo({ categoryNo, classNo: ticketClass.no, ...restProps })
    );
    const tcSettings = await Promise.all(tcSettingPromises);

    // construct tcSetting tree
    const rootTcSetting = await this._getTicketCustomizeSettingByCategoryNoClassNo({
      categoryNo,
      classNo: null,
      ...restProps,
    });
    const len = tcSettings.length;
    for (let i = 1; i < len; i++) {
      tcSettings[i - 1].parent = tcSettings[i];
    }

    // 將最上層 class setting 的 parent 設定為 category 的 TcSetting
    if (len >= 1) {
      tcSettings[len - 1].parent = rootTcSetting;
    }

    let tcSetting = null;
    if (len === 0) {
      tcSetting = rootTcSetting;
    } else if (len > 0) {
      tcSetting = tcSettings[0];
    }

    return tcSetting;
  };

  static setTicketCustomizeSetting = async (...params) => {
    const apiProvider = this.getTicketCffApiProvider();
    const tcSettingDict = await apiProvider.setTicketCustomizeSetting(...params).then((res) => res.data.data);
    const tcSetting = new OzTicketCustomizeSetting().fromDict(tcSettingDict);

    return tcSetting;
  };

  static getTicketCIRelationsByTicketNo = async (...params) => {
    const ticketCIRelationDicts = await findTicketCIRelationsByTicketNo(...params).then((res) => res.data.data);
    const ticketCIRelations = ticketCIRelationDicts.map((ticketCIRelationDict) =>
      new OzTicketCIRelation().fromDict(ticketCIRelationDict)
    );

    return ticketCIRelations;
  };
}
