import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

import AppLoginHeader from "../components/admin/AppLoginHeader";
import AppContent from "../components/admin/AppContent";
import AppLoginSidebar from "../components/admin/AppLoginSidebar";
import { GetUserNavAction } from '../store/action/base/NavAction';
import { loginMenuAction } from '../store/loginMenuSlice';
import { ProjectAction } from "../store/action/project/projectAction";
import { UserPage } from "../js/Base/UserPage";
import { OzApiProvider } from "../js/Service/Ticket/api";
import { CategoryClassAction } from "../store/action/category/categoryClassAction";

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { createMsalConfig, logoutRequest } from "../configs/AzureSSOauthConfig";

class AdminLayout extends (UserPage, OzApiProvider) {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      isOpenTicketAside: false,
    };
  }

  componentDidMount = async () => {
    const userData = this.GetUser();
    if (userData.token === 'EXPIRED') {
      if (localStorage.getItem('msal.account.keys')) {
        this.MsalSignOut();
      } else {
        this.SignOut();
      }
    } else {
     
      const project_no = Number(localStorage.getItem('project_no'));
      const company_no = Number(localStorage.getItem('company_no'));
      this.getIncidentCategoryNo(project_no);
      this.getUserNav(project_no, company_no, userData.no);
    }
  };

  componentWillUnmount() {
    if (this.state.isOpenTicketAside) this.child.current.toggle();
  }

  componentDidUpdate = async (prevProps) => {
    const projectNo = Number(localStorage.getItem('project_no'));
    const companyNo = Number(localStorage.getItem('company_no'));
    
    if (prevProps.projectNo !== projectNo && projectNo !== 0) {
      const userData = this.GetUser();
      this.getUserNav(projectNo, companyNo, userData.no);
    }
  };

  getUserNav = async (projectNo, companyNo, acct_no) => {
    await this.props.GetUserNav(acct_no, projectNo, companyNo, this.GetWebApiConfig());
  };

  getIncidentCategoryNo = async (projectNo) => {
    await this.props.GetCategoryByProjectNo(projectNo, (res) => {
      const incident_no = res.find((val) => val.name === 'Incident')?.no;
      localStorage.setItem('category_no', incident_no);
    });
  }

  MsalSignOut = async () => {
    const msalID = JSON.parse(localStorage.getItem('msal.config.id')) ? JSON.parse(localStorage.getItem('msal.config.id')) : {};
    const msalConfig = createMsalConfig(msalID.clientId, msalID.tenantId);
    const msalInstance = new PublicClientApplication(msalConfig);
    const init = await msalInstance.initialize();  

    this.SignOut();
    msalInstance.logoutRedirect(logoutRequest);
    localStorage.removeItem("isAzureSSO");
  };
  
  render() {
    const drawerWidth = 230;
    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }));
    const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    }));
    const userNav = JSON.parse(localStorage.getItem("userNav")) ? JSON.parse(localStorage.getItem("userNav")) : {}

    return (
      <Fragment>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <ToastContainer position="top-center" />
          <AppLoginHeader tlns={"AppHeader"} onLogout={this.SignOut} userNav={userNav} {...this.props} />
          <AppLoginSidebar tlns={"AppHeader"} {...this.props} userNav={userNav} />
          <Main open={this.props.openSideBar}>
            <DrawerHeader />
            <AppContent tlns={"AppContent"} {...this.props} />
          </Main>
        </Box>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // userNav: state.nav.userNav,
    project: state.project.all_manageable_project,
    openSideBar: state.loginMenu.openSideBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  const project_action = new ProjectAction();
  const category_class_action = new CategoryClassAction();
  const get_user_nav_action = new GetUserNavAction();
  return {
    GetMyProject: (acct_no, success_callback = null, error_callback = null) =>
      dispatch(project_action.GetMyProjectList(acct_no, success_callback, error_callback)),
    FindManageableProjectByCompanyNoList: (company_no_list, success_callback = null, error_callback = null) =>
      dispatch(project_action.FindManageableProjectByCompanyNoList(company_no_list, success_callback, error_callback)),
    GetCategoryByProjectNo: (projectNo, success_callback = null, error_callback = null) =>
      dispatch(category_class_action.GetCategoryByProjectNo(projectNo, success_callback, error_callback)),
    GetUserNav: (acctNo, projectNo, companyNo, userWebApiConfig) =>
      dispatch(get_user_nav_action.GetUserNav(acctNo, projectNo, companyNo, userWebApiConfig)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
