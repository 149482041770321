const initState = {
  workspace_by_no: {},
  workspace_by_project_no: [],
  update_workspaces: []
}

const cloudLogAnalyticsWorkspacesReducer = (state = initState, action) => {
  switch (action.type) {
    case 'Error':
      return {
        ...state,
        errorcode: action.payload ? action.payload : 500,
      }
    case 'FindWorkspaceByNo_SUCCESS':
      return {
        ...state,
        workspace_by_no: action.payload,
        errorcode: null,
      }
    case 'FindWorkspacesByProjectNo_SUCCESS':
      return {
        ...state,
        workspace_by_project_no: action.payload,
        errorcode: null,
      }
    case 'UpdateWorkSpaces_SUCCESS':
      return {
        ...state,
        update_workspaces: action.payload,
        errorcode: null,
      }
    default:
      return state
  }
}
export default cloudLogAnalyticsWorkspacesReducer