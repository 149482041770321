const initState = {
  ticket_event: [],
  errorcode: null,
}

const ticketEventReducer = (state = initState, action) => {

  switch (action.type) {
    case 'Error':
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500
      }

    case 'GetTicketEventByTicketNo_SUCCESS':
      return {
        ...state,
        ticket_event: [...state.ticket_event.filter(e=>e.no !== action.payload.no), ...action.payload],
        errorcode: null
      }
    default:
      return state;
  }
}

export default ticketEventReducer