import React from 'react';

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { createMsalConfig, logoutRequest } from '../../configs/AzureSSOauthConfig';

const HocLoginCounter = (Component) =>
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        expirationDate: localStorage.getItem('expiration'),
        timeoutId: null, // 用於存儲 setTimeout ID
        isMsalAccount: localStorage.getItem('msal.account.keys'),
      };
    }

    componentDidUpdate(prevProps) {
      if (this.props !== prevProps) {
        this.resetExpiration();
      }
    }

    componentWillUnmount() {
      if (this.state.timeoutId) {
        clearTimeout(this.state.timeoutId);
      }
    }

    resetExpiration() {
      const expiration = new Date(this.state.expirationDate);
      const now = new Date();
      const tokenDuration = expiration.getTime() - now.getTime();

      if (tokenDuration < 0) {
        if (this.state.isMsalAccount) {
          this.MsalSignOut();
        } else {
          this.SignOut();
        }
      } else {
        now.setHours(now.getHours() + 1); // 1 小時
        // now.setMinutes(now.getMinutes() + 5); // 1 分鐘
        localStorage.setItem('expiration', now.toISOString());
        this.setState({ expirationDate: now.toISOString() }, this.setupTimeout);
      }
    }

    setupTimeout = () => {
      const newTokenDuration = 60 * 60 * 1000; // 1 小時的毫秒數
      // const newTokenDuration = 5 * 60 * 1000; // 1 小時的毫秒數

      const timeoutId = setTimeout(() => {
        if (this.state.isMsalAccount) {
          this.MsalSignOut();
        } else {
          this.SignOut();
        }
      }, newTokenDuration);

      this.setState({ timeoutId });
    }

    MsalSignOut = async () => {
      const msalID = JSON.parse(localStorage.getItem('msal.config.id')) ? JSON.parse(localStorage.getItem('msal.config.id')) : {};
      const msalConfig = createMsalConfig(msalID.clientId, msalID.tenantId);
      const msalInstance = new PublicClientApplication(msalConfig);
      const init = await msalInstance.initialize();  

      this.SignOut();
      msalInstance.logoutRedirect(logoutRequest);
      localStorage.removeItem("isAzureSSO");
    };

    SignOut() {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('lan');
      localStorage.removeItem('name');
      localStorage.removeItem('no');
      localStorage.removeItem('timezone');
      localStorage.removeItem('company_no');
      localStorage.removeItem('project_no');
      localStorage.removeItem('company_name');
      localStorage.removeItem('expiration');
      localStorage.removeItem('company_list');
      localStorage.removeItem('project_name');
      localStorage.removeItem('project_list');
      localStorage.removeItem('category_no');

      localStorage.removeItem('openSideBar');
      localStorage.removeItem('firstMenu');
      localStorage.removeItem('selectedFirstMenu');
      localStorage.removeItem('secondMenu');
      localStorage.removeItem('thirdMenu');
      localStorage.removeItem('thirdMenuList');
      localStorage.removeItem('userNav');

      localStorage.removeItem('autoscan');
      localStorage.removeItem('recent_page');
      localStorage.removeItem('provider');
      localStorage.removeItem('selectedRegion');
      localStorage.removeItem('currencyRates');
      localStorage.removeItem('selectedRows');
      this.GoToLogin();
    }

    GoToLogin = () => {
      window.location = '/login';
    };

    render() {
      return (
        <>
          <Component {...this.props} />
        </>
      );
    }
  };

export default HocLoginCounter;
