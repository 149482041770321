import hash from "object-hash";

import { OzCustomBasicObj } from "../../js/Domain/Common/struct";
import { OzTicketCff, TicketFieldValue, OzTicketCIRelation, OzTicketSecurityReport } from '../../js/Domain/Ticket/struct'

import { isNullOrUndefined } from "../../js/Utils/utils";
import { addPrefix } from "../../js/Utils/string";
// import CustomNotification from "../../views/Component/Common/Notification";
import { FIELD_PREFIX, InputBoxType, REF_ITEM_PREFIX } from "../../js/Domain/CMDB/utils";
import { deepcopy } from "../../js/Utils/utils";

import APIHelper from "../../js/Service/Ticket/ApiHelper";
import i18n from "i18next";

export class OzTicket extends OzCustomBasicObj {
  constructor() {
    super();
    this.no = null;
    this.id = null;
    this.project_no = null;
    this.category_no = "0";
    this.ticket_class_no = null;
    this.assign_group_no = "";
    this.assign_acct_no = "0";
    this.create_acct_no = null;
    this.apply_acct_no = null;
    this.create_acct_no = null;
    this.name = "";
    this.sub_name = "";
    this.ticket_desc = "";
    this.status_no = 2;
    this.urgency_no = null;
    this.impact_no = null;
    this.priority_no = null;
    this.ticket_relations = [];
    this.ci_relations = [];
    this.change_ci_relations = [];
    this.sub_ticket = [];
    // this.cff = new OzTicketCff();
    this.cff = null;
    this.field_values = [];
    this.master_ticket_no = null;
    this.expected_time = null;
    this.create_dt = null;
    this.update_dt = null;
    this.close_dt = null;
    this.bind_main_ticket_no = null;
    this.is_bind_main_ticket = null;

    this.edc_project_no = null;
    this.edc_oid = null;
    this.edc_project_name = null;
    this.edc_task_process_state = null;

    this.mongo_db_name = null;
    this.mongo_table_name = null;
    this.security_report = null;
    this.incident_classification = null;
    this.incident_classification_reason = null;
    this.is_vip = null;
  }

  GetSummary() {
    if (this.summary) return this.summary;
    if (this.sub_name) return `${this.name} : ${this.sub_name}`;
    return this.name;
  }

  GetHash() {
    return hash(this);
  }

  ToCreate() {
    this.no = null;
    this.assign_acct_no = this.assign_acct_no === "0" ? null : this.assign_acct_no;
    if (this.sub_ticket && this.sub_ticket.length > 0) {
      this.sub_ticket.forEach((s) => s.ToCreate());
    }
  }

  copyTicket(ticket) {
    //清空資料編號

    let copyTicket = deepcopy(ticket);
    copyTicket.DeleteOtherTicketData()
    // copyTicket.no = null;
    // copyTicket.master_ticket_no = null
    // copyTicket.ci_relations.forEach(cr => cr.no = null);
    // for (let i = 0; i < copyTicket.field_values.length; i++) {
    //   copyTicket.field_values[i].no = null;
    //   copyTicket.field_values[i].ticket_no = null;
    //   copyTicket.field_values[i].custom_field_value_no = null;
    // }
    // for (let i = 0; i < copyTicket.sub_ticket.length; i++)
    //   copyTicket.sub_ticket[i] = new OzTicket().cloneData(copyTicket.sub_ticket[i]);

    return copyTicket;
  }

  DeleteOtherTicketData() {
    this.no = null;
    this.master_ticket_no = null
    this.ci_relations.forEach(cr => cr.no = null);
    for (let i = 0; i < this.field_values.length; i++) {
      this.field_values[i].no = null;
      this.field_values[i].ticket_no = null;
      this.field_values[i].custom_field_value_no = null;
    }
    for (let i = 0; i < this.sub_ticket.length; i++)
      this.sub_ticket[i] = new OzTicket().cloneData(this.sub_ticket[i]);
  }

  SetTicketTemplate(template_ticket) {
    // console.log(template_ticket)
    if (template_ticket.name) this.name = template_ticket.name;
    if (template_ticket.sub_name) this.sub_name = template_ticket.sub_name;
    if (template_ticket.ticket_desc) this.ticket_desc = template_ticket.ticket_desc;
    if (template_ticket.ticket_class_no) this.ticket_class_no = template_ticket.ticket_class_no;
    if (template_ticket.urgency_no) this.urgency_no = template_ticket.urgency_no;
    if (template_ticket.impact_no) this.impact_no = template_ticket.impact_no;
    if (template_ticket.priority_no) this.priority_no = template_ticket.priority_no;
    if (template_ticket.assign_group_no) this.assign_group_no = template_ticket.assign_group_no;
    if (template_ticket.assign_acct_no) this.assign_acct_no = template_ticket.assign_acct_no;
    if (template_ticket.project_no) this.project_no = template_ticket.project_no;
    if (template_ticket.category_no) this.category_no = template_ticket.category_no;

    // console.log(template_ticket.sub_ticket)
    if (!this.master_ticket_no) {
      let sub_ticket_no = 1;
      this.sub_ticket = template_ticket.sub_ticket.map((sub) => {
        let sub_ticket = new OzTicket();
        sub_ticket.no = sub_ticket_no;
        sub_ticket.SetTicketTemplate(sub);
        sub_ticket_no++;
        return sub_ticket;
      });
    }
  }

  fromDict(ticketDict) {
    super.fromDict(ticketDict);

    if (!isNullOrUndefined(ticketDict)) {
      if (ticketDict.cff)
        this.cff = new OzTicketCff().fromDict(ticketDict.cff);
      this.field_values = (ticketDict.field_values || []).map((fv) => new TicketFieldValue().fromDict(fv));
      this.ci_relations = (ticketDict.ci_relations || []).map((tcr) => new OzTicketCIRelation().fromDict(tcr))
      this.sub_ticket = (ticketDict.sub_ticket || []).map((st) => {
        const sub_ticket = new OzTicket().fromDict(st)
        return sub_ticket
      });
      // 轉資安警訊
      if (ticketDict.ticket_no && ticketDict.project_no) {
        const tick = new OzTicketSecurityReport()
        tick.no = ticketDict.security_report_no
        tick.ticket_no = ticketDict.ticket_no
        tick.project_no = ticketDict.project_no
        tick.submit_date = ticketDict.submit_date
        tick.attack_source_ip = ticketDict.attack_source_ip
        tick.attack_source_port = ticketDict.attack_source_port
        tick.attack_source_host = ticketDict.attack_source_host
        tick.attack_source_country = ticketDict.attack_source_country
        tick.attack_source_ipv6 = ticketDict.attack_source_ipv6
        tick.attack_dest_ip = ticketDict.attack_dest_ip
        tick.attack_dest_port = ticketDict.attack_dest_port
        tick.attack_dest_host = ticketDict.attack_dest_host
        tick.attack_dest_country = ticketDict.attack_dest_country
        tick.attack_dest_ipv6 = ticketDict.attack_dest_ipv6
        tick.virus_host = ticketDict.virus_host
        tick.virus_name = ticketDict.virus_name
        tick.happen_number = ticketDict.happen_number
        tick.event_id = ticketDict.event_id
        tick.techniques = ticketDict.techniques
        tick.cloud_security_rule_no = ticketDict.cloud_security_rule_no
        tick.kql_query = ticketDict.kql_query
        tick.query_period = ticketDict.query_period
        tick.query_start_time = ticketDict.query_start_time
        tick.query_end_time = ticketDict.query_end_time
        tick.cht_name = ticketDict.cht_name
        tick.cht_desc = ticketDict.cht_desc
        tick.eng_phenomenon = ticketDict.eng_phenomenon
        tick.cht_phenomenon = ticketDict.cht_phenomenon
        tick.eng_impact = ticketDict.eng_impact
        tick.cht_impact = ticketDict.cht_impact
        tick.eng_analysis_result = ticketDict.eng_analysis_result
        tick.cht_analysis_result = ticketDict.cht_analysis_result
        tick.eng_remediation = ticketDict.eng_remediation
        tick.cht_remediation = ticketDict.cht_remediation
        tick.attack_source_file_name = ticketDict.attack_source_file_name
        tick.attack_source_file_path = ticketDict.attack_source_file_path
        tick.attack_source_user_name = ticketDict.attack_source_user_name
        tick.attack_source_file_hash = ticketDict.attack_source_file_hash
        tick.device_host = ticketDict.device_host
        tick.device_address = ticketDict.device_address
        tick.device_action = ticketDict.device_action
        tick.alert_product_name = ticketDict.alert_product_name
        tick.alert_uri = ticketDict.alert_uri
        tick.attack_dest_user_name = ticketDict.attack_dest_user_name
        tick.attack_source_uri = ticketDict.attack_source_uri
        tick.attack_dest_uri = ticketDict.attack_dest_uri
        tick.request_url = ticketDict.request_url
        tick.virus_host = ticketDict.virus_host
        tick.virus_name = ticketDict.virus_name
        tick.fusion_alert_event = ticketDict.fusion_alert_event
        tick.alert_provider_name = ticketDict.alert_provider_name
        tick.aip_client_ip = ticketDict.aip_client_ip
        tick.aip_client_location = ticketDict.aip_client_location
        tick.aip_request_id = ticketDict.aip_request_id
        tick.aip_detail = ticketDict.aip_detail
        tick.aip_timing = ticketDict.aip_timing
        tick.aip_detection = ticketDict.aip_detection
        tick.aip_tenant_login_source = ticketDict.aip_tenant_login_source
        tick.destination_service_name = ticketDict.destination_service_name
        tick.alert_link = ticketDict.alert_link
        tick.sentinel_number = ticketDict.sentinel_number
        tick.is_revise_user_name = ticketDict.is_revise_user_name
        tick.revise_source_user_name = ticketDict.revise_source_user_name
        tick.revise_dest_user_name = ticketDict.revise_dest_user_name
        this.security_report = tick
      }
    }

    return this;
  }

  findCIRelationsByFieldValueNo = (customFieldValueNo) => {
    return this.ci_relations.filter((relation) => relation.custom_field_value_no === customFieldValueNo);
  }

  findCIRelationsByDesc = (desc) => {
    return this.ci_relations.filter((relation) => relation.getDesc() === desc);
  }

  findCIRelation(fieldValue, customField) {
    throw new TypeError("not yet implemented");
    // // 用 field no 找 relations
    // let relations = this.findCIRelationsByFieldValueNo(fieldValue.custom_field_value_no);
    // // 為了相容舊資料，則用 field name 作為 desc 去找
    // if (relations.length === 0) {
    //   relations = this.findCIRelationsByDesc(customField.name);
    // }

    // this.checkCIRelation(relations, customField);

    // return relations[0];
  }

  // Todo: duplicate!!!
  // Todo: this part of code is copy paste from ticket.js
  convertFieldValuesToFormikValues = () => {
    const ticketFieldValueDict = {};
    (this.field_values || []).forEach((fieldValue) => {
      const { field } = fieldValue;
      const fieldNo = field.no;

      const name = addPrefix(fieldNo, FIELD_PREFIX);
      const prefixedItems = fieldValue.options.map((option) => addPrefix(option.item_no, REF_ITEM_PREFIX));

      let value;
      let errMsg;
      switch (InputBoxType.Enum[field.input_box_type]) {
        case InputBoxType.Enum.TextBox:
        case InputBoxType.Enum.TextArea:
        case InputBoxType.Enum.DatePicker:
        case InputBoxType.Enum.DateTimePicker:
          value = fieldValue.value;
          break;

        case InputBoxType.Enum.ComboBox:
        case InputBoxType.Enum.DropDown:
        case InputBoxType.Enum.MultiLevelDropDown:
          value = prefixedItems[0];
          break;

        case InputBoxType.Enum.CheckBox:
          value = prefixedItems;
          break;

        case InputBoxType.Enum.Table:
          value = null;
          break;

        case InputBoxType.Enum.TableColumn:
          // concat
          const colValueArr = ticketFieldValueDict[name] ? ticketFieldValueDict[name] : [];
          value = [...colValueArr, fieldValue.value];
          break;

        default:
          errMsg = `InputBoxType ${i18n.t('unrecognized', { 'unrecognized': 'unrecognized' })}: ${fieldValue.field.input_box_type}, field_no: ${fieldValue.field.no}, field_name: ${fieldValue.field.name}`;
          // CustomNotification.error(errMsg);
      }

      ticketFieldValueDict[name] = value;
    });

    return ticketFieldValueDict;
  }

  // 不知做啥的 有很大的問題
  changeFields(target_cff) {
    const original_fields = this.cff.getFields();
    const target_fields = target_cff.getFields();
    let match_fields_map = new Map();
    for (let i = 0; i < original_fields.length; i++) {
      const same_name_field = target_fields.find(field => field.name === original_fields[i].name);
      if (same_name_field)
        match_fields_map.set(original_fields[i].no, same_name_field.no)
    }
    let newFieldValues = []
    for (let i = 0; i < this.field_values.length; i++) {
      const new_field_no = match_fields_map.get(this.field_values[i].field_no);
      if (new_field_no) {
        let match_field = this.field_values[i];
        match_field.field_no = new_field_no;
        newFieldValues.push(match_field);
      }
    }
    this.field_values = newFieldValues;
    this.cff = target_cff;
  }

  GetEDCProjectDataNo() {
    const project_name = "專案名稱"
    const project_no = "專案編號"
    const oid = "OID"
    let project_name_fv_no = 0
    let project_no_fv_no = 0
    let oid_fv_no = 0

    if (this.cff) {
      this.cff.field_groups.forEach(fg => {
        fg.fields.forEach(f => {
          if (f.name === project_name)
            project_name_fv_no = f.no
          if (f.name === project_no)
            project_no_fv_no = f.no
          if (f.name === oid)
            oid_fv_no = f.no
        })
      })

      return [project_name_fv_no, project_no_fv_no, oid_fv_no]
    }
    return []
  }

  GetEDCProjectFormikData() {
    const nos = this.GetEDCProjectDataNo()
    let project_name_fv_no = nos[0]
    let project_no_fv_no = nos[1]
    let oid_fv_no = nos[2]

    let data = []
    if (project_name_fv_no !== 0) {
      let project_name_fv = this[addPrefix(project_name_fv_no, FIELD_PREFIX)]
      if (project_name_fv)
        data = [...data, project_name_fv]
    }
    if (oid_fv_no !== 0) {
      const oid_fv = this[addPrefix(oid_fv_no, FIELD_PREFIX)]
      if (oid_fv)
        data = [...data, oid_fv]
    }
    if (project_no_fv_no !== 0) {
      const project_no_fv = this[addPrefix(project_no_fv_no, FIELD_PREFIX)]
      if (project_no_fv)
        data = [...data, project_no_fv]
    }
    return (data.length === 3) ? data : null
  }

  GetEDCProjectData() {

    const nos = this.GetEDCProjectDataNo()
    let project_name_fv_no = nos[0]
    let project_no_fv_no = nos[1]
    let oid_fv_no = nos[2]

    let data = []
    if (project_name_fv_no !== 0) {
      let project_name_fv = this.GetEDCData(project_name_fv_no, "專案名稱")
      if (project_name_fv)
        data = [...data, project_name_fv]
    }
    if (oid_fv_no !== 0) {
      const oid_fv = this.GetEDCData(oid_fv_no, "OID")
      if (oid_fv)
        data = [...data, oid_fv]
    }
    if (project_no_fv_no !== 0) {
      const project_no_fv = this.GetEDCData(project_no_fv_no)
      if (project_no_fv)
        data = [...data, project_no_fv]
    }

    return (data.length === 3) ? data : null
  }

  GetEDCData(fv_no, fv_name) {
    let fv = this[addPrefix(fv_no, FIELD_PREFIX)]
    if (!fv) {
      fv = this.field_values.find(fv => fv.field_no === fv_no)
      if (fv instanceof TicketFieldValue && fv_name) {
        fv.ci = this.ci_relations.find(ci => ci.desc === fv_name)?.ci
      }
    }
    return fv ? fv : null
  }

  async SetEDCProjectData(edc_data, master_ticket = null) {
    const project_name = "專案名稱"
    const project_no = "專案編號"
    const oid = "OID"
    let project_name_fv_no = 0
    let project_no_fv_no = 0
    let oid_fv_no = 0

    if (!this.cff || !this.cff.no)
      this.cff = (await this.getTcSettingFromApi(this.category_no, this.ticket_class_no))
    if (this.cff && edc_data && edc_data.length === 3) {
      this.cff.field_groups.forEach(fg => {
        fg.fields.forEach(f => {
          if (f.name === project_name)
            project_name_fv_no = f.no
          if (f.name === project_no)
            project_no_fv_no = f.no
          if (f.name === oid)
            oid_fv_no = f.no
        })
      })

      if (project_name_fv_no !== 0) {
        const project_name_no = addPrefix(project_name_fv_no, FIELD_PREFIX)
        if (project_name_no) {
          this.ci_relations = [...this.ci_relations.filter(r => r.desc !== project_name)]
          const ci_relation = master_ticket.ci_relations.find(ci => ci.desc === project_name)
          // console.log(ci_relation)
          // if (ci_relation)
          //   this.ci_relations = [...this.ci_relations, deepcopy(ci_relation)]
          if (edc_data[0] instanceof TicketFieldValue) {
            this[project_name_no] = {
              no: ci_relation.ci_no,
              value: ci_relation?.ci.name
            }
            const fv = this.field_values.find(fv => fv.field_no === project_name_fv_no)
            if (fv)
              fv.value = ci_relation.ci_no
          }
          else {
            this[project_name_no] = edc_data[0]
            if (this[project_name_no]) {
              this.edc_project_name = this[project_name_no]?.value
              this.setFieldValue(project_name_fv_no, this[project_name_no]?.no)
            }
          }
        }
      }


      if (oid_fv_no !== 0) {
        const oid_no = addPrefix(oid_fv_no, FIELD_PREFIX)
        if (oid_no) {
          this.ci_relations = [...this.ci_relations.filter(r => r.desc !== oid)]
          const ci_relation = master_ticket.ci_relations.find(ci => ci.desc === oid)
          // if (ci_relation)
          //   this.ci_relations = [...this.ci_relations, deepcopy(ci_relation)]

          if (edc_data[1] instanceof TicketFieldValue) {
            this[oid_no] = {
              no: ci_relation.ci_no,
              value: ci_relation?.ci.name
            }

            const fv = this.field_values.find(fv => fv.field_no === oid_fv_no)
            if (fv)
              fv.value = ci_relation.ci_no
          }
          else {
            this[oid_no] = edc_data[1]
            if (this[oid_no]) {
              this.edc_oid = this[oid_no]?.value
              this.setFieldValue(oid_fv_no, this[oid_no]?.no)
            }
          }
        }
      }
      if (project_no_fv_no !== 0) {
        const project_no_no = addPrefix(project_no_fv_no, FIELD_PREFIX)
        if (project_no_no) {
          if (edc_data[2] instanceof TicketFieldValue) {
            this[project_no_no] = edc_data[2].value
          }
          else {
            this[project_no_no] = edc_data[2]
            if (this[project_no_no]) {
              this.edc_project_no = this[project_no_no]
              this.setFieldValue(project_no_fv_no, this[project_no_no])
            }
          }
        }
      }

    }
  }

  setFieldValue(field_no, value) {
    let fv = this.field_values.find(f => f.field_no === parseInt(field_no))
    if (!fv) {
      fv = new TicketFieldValue(null, value, null, null, this.no, field_no)
      // fv = new TicketFieldValue(null,value,null,null,null,field_no,null,null)
      this.field_values = [...this.field_values, fv]
    }
    else
      fv.value = value
  }

  getTcSettingFromApi = async (ticket_category_no, ticket_class_no) => {
    const cff = await APIHelper.getTicketCffByClassNo({
      ticket_category_no,
      ticket_class_no,
      with_field_groups: true,
    });
    return cff;
  };

}
