import { isNullOrUndefined } from "../../Utils/utils";
import { InputBoxType } from "./utils";
import { isEmptyString } from "../../Utils/string";
import { isEmptyArray } from "../../Utils/array";
import { isEmptyObject } from "../../Utils/object";

import OzViewModel, { CustomFieldOption, CustomFieldValue, OzCustomBasicObj, OzFieldGroup } from "../../../js/Domain/Common/struct";
import { ArrayHasMoreThanOneElementError, ArrayIsEmptyError } from "../../../js/Domain/OzError";

export class CmdbClass extends OzViewModel {
  constructor(no = null, company_no = null, name = "", enable = true, enable_file_upload = false, field_groups = []) {
    super();
    this.no = no;
    this.company_no = company_no;
    this.name = name;
    this.enable = enable;
    this.enable_file_upload = enable_file_upload;
    this.field_groups = field_groups
      .map((fg) => new CmdbClassFieldGroup().fromDict(fg))
      .sort((a, b) => a.order - b.order);
  }

  fromDict(classDict) {
    super.fromDict(classDict);

    if (!isNullOrUndefined(classDict)) {
      this.field_groups = (classDict.field_groups || [])
        .map((fg) => new CmdbClassFieldGroup().fromDict(fg))
        .sort((a, b) => a.order - b.order);
    }

    return this;
  }

  addNullItemInField = () => {
    this.field_groups.forEach((fg) => fg.addNullItemInField());
  };

  getFieldByNo = (fieldNo) => {
    return this.getFields(true).find((field) => field.no === fieldNo);
  };

  getFieldByName = (fieldName) => {
    return this.getFields(true).find((field) => field.name === fieldName);
  };

  getFields = (flatSubField = false) => {
    return this.field_groups.map((group) => group.getFields(flatSubField)).flat();
  };

  filterDisable = () => {
    this.field_groups = this.field_groups.filter((fg) => fg.enable);

    // 過濾 field group 的 field
    this.field_groups.forEach((fg) => fg.filterDisable());
  };

  filterDelete = () => {
    this.field_groups = this.field_groups.filter((fg) => !fg.deleted);

    // 過濾 field group 的 field
    this.field_groups.forEach((fg) => fg.filterDelete());
  };

  filterTableColumn = () => {
    // 過濾 field group
    this.field_groups.forEach((fg) => fg.filterTableColumn());
  };
}

export class CmdbClassFieldGroup extends OzFieldGroup {
  constructor(
    no = null,
    custom_field_group_no = null,
    class_no = null,
    name = "",
    enable = true,
    deleted = false,
    order = null,
    fields = []
  ) {
    super(no, name, enable, deleted, order, fields);

    this.custom_field_group_no = custom_field_group_no;
    this.class_no = class_no;

    // for react-beautiful-dnd
    this.isExpend = true;
  }

  filterDisable = () => {
    this.fields = this.fields.filter((field) => field.enable);

    // 過濾 field 的 items
    this.fields.forEach((field) => field.filterDisable());
  };

  filterDelete = () => {
    this.fields = this.fields.filter((field) => !field.deleted);

    // 過濾 field 的 items
    this.fields.forEach((field) => field.filterDelete());
  };

  filterTableColumn = () => {
    this.fields = this.fields.filter(
      (field) => InputBoxType.Enum[field.input_box_type] !== InputBoxType.Enum.TableColumn
    );
  };

  fromDict(fgDict) {
    super.fromDict(fgDict);

    if (!isNullOrUndefined(fgDict)) {
      this.custom_field_group_no = fgDict.custom_field_group_no;
      this.class_no = fgDict.class_no;
    }

    return this;
  }
}

export class OzCI extends OzCustomBasicObj {
  constructor(
    no = null,
    company_no = null,
    class_no = null,
    name = "",
    enable = null,
    description = "",
    memo = "",
    version = "",
    create_time = null,
    update_time = null,
    field_values = [],
    cmdb_class = null,
    relations = []
  ) {
    super();
    this.no = no;
    this.company_no = company_no;
    this.class_no = class_no;
    this.name = name;
    this.enable = enable;
    this.description = description;
    this.memo = memo;
    this.version = version;
    this.create_time = create_time;
    this.update_time = update_time;
    this.field_values = field_values.map((fv) => new OzCIFieldValue().fromDict(fv));
    this.cmdb_class = new CmdbClass().fromDict(cmdb_class);
    this.relations = relations.map((relation) => new OzCIRelation().fromDict(relation));
  }

  fromDict(ciDict) {
    super.fromDict(ciDict);
    if (!isNullOrUndefined(ciDict)) {
      this.field_values = (ciDict.field_values || []).map((fv) => new OzCIFieldValue().fromDict(fv));
      this.cmdb_class = new CmdbClass().fromDict(ciDict.cmdb_class);
      this.relations = (ciDict.relations || []).map((rDict) => new OzCIRelation().fromDict(rDict));
    }

    return this;
  }

  findCIRelationByRelationNo(relationNo) {
    return this.relations.find((relation) => relation.no === relationNo);
  }

  findCIRelationsByDesc(desc) {
    return this.relations.filter((relation) => relation.desc === desc);
  }

  findCIRelation({ relationNo, fieldName, isEssential, byDesc = true }) {
    const relation = this.findCIRelationByRelationNo(relationNo);

    // 為了相容舊資料，則用 field name 作為 desc 去找
    let relations = [];
    if (!isNullOrUndefined(relation)) {
      relations = [relation];
    } else if (byDesc) {
      relations = this.findCIRelationsByDesc(fieldName);
    }

    // 檢查關聯
    try {
      this.checkCIRelation(relations);
    } catch (error) {
      if (error instanceof ArrayIsEmptyError) {
        if (isEssential) {
          // CustomNotification.error(`${i18n.t('field', { 'field': 'field' })}「${fieldName}」找不到關聯`);
        }
      } else if (error instanceof ArrayHasMoreThanOneElementError) {
        // CustomNotification.error(`${fieldName} ${i18n.t('multiple-association', { 'multiple-association': 'multiple-association' })}`);
      }
    }

    return relations[0];
  }

  filterDisable = () => {
    if (this.cmdb_class?.enable) {
      this.cmdb_class.filterDisable();
    }
  };

  filterDelete = () => {
    if (this.cmdb_class?.enable) {
      this.cmdb_class.filterDelete();
    }

    this.relations = (this.relations || []).filter((relation) => !relation.deleted);
  };

  static getPropText = (propName) => {
    let text = "";
    switch (propName) {
      case "name":
        text = "組態名稱";
        break;

      case "enable":
        text = "狀態";
        break;

      case "deleted":
        text = "刪除";
        break;

      case "description":
        text = "描述";
        break;

      case "memo":
        text = "備註";
        break;

      case "version":
        text = "版本";
        break;

      case "create_time":
        text = "創建時間";
        break;

      case "update_time":
        text = "更新時間";
        break;

      default:
        text = propName;
        break;
    }

    return text;
  };
}

export class CmdbTempCI extends OzCI {
  constructor(
    no = null,
    company_no = null,
    class_no = null,
    name = "",
    enable = null,
    description = "",
    memo = "",
    version = "",
    create_time = null,
    update_time = null,
    field_values = [],
    original_instance_no = null,
    related_ticket_no = null,
    cmdb_class = null,
    relations = [],
  ) {
    super(no, company_no, class_no, name, enable, description, memo, version, create_time, update_time, field_values, cmdb_class, relations);
    this.original_instance_no = original_instance_no;
    this.related_ticket_no = related_ticket_no;
  }
}

export class OzCIFieldValue extends CustomFieldValue {
  constructor(
    no = null,
    value = null,
    row_no = null,
    custom_field_value_no = null,
    ci_no = null,
    field_no = null,
    relation_no = null,

    field = null,
    options = []
  ) {
    super(no, value, row_no, field_no, field, options);
    this.no = no;
    this.value = value;
    this.row_no = row_no;
    this.custom_field_value_no = custom_field_value_no;
    this.ci_no = ci_no;
    this.field_no = field_no;
    this.relation_no = relation_no;

    this.options = options.map((option) => new CustomFieldOption().fromDict(option));
  }

  fromDict(ciFvDict) {
    super.fromDict(ciFvDict);

    if (!isNullOrUndefined(ciFvDict)) {
      this.options = (ciFvDict.options || []).map((option) => new CustomFieldOption().fromDict(option));
    }

    return this;
  }
}

export class OzCIHistory extends OzViewModel {
  constructor(no = null, ci_no = null, create_time = null, ticket_no = null, who = null, args = [], acct = null) {
    super();
    this.isExpend = false;
    this.no = no;
    this.ci_no = ci_no;
    this.create_time = create_time;
    this.ticket_no = ticket_no;
    this.who = who;
    this.args = args.map((arg) => new OzCIHistoryArg().fromDict(arg));
    this.acct = acct;
  }

  fromDict(historyDict) {
    super.fromDict(historyDict);

    if (!isNullOrUndefined(historyDict)) {
      this.args = (historyDict.args || []).map((arg) => new OzCIHistoryArg().fromDict(arg));
    }

    return this;
  }
}

export class OzCIHistoryArg extends OzViewModel {
  constructor(no = null, history_no = null, action = "", name = "", old_value = "", new_value = "", row_no = null) {
    super();
    this.no = no;
    this.history_no = history_no;
    this.action = action;
    this.name = name;
    this.old_value = old_value;
    this.new_value = new_value;
    this.row_no = row_no;
  }

  getActionText = () => {
    let text = "";
    switch (this.action) {
      case "CREATE_VALUE":
        text = "創建";
        break;

      case "UPDATE_VALUE":
        text = "更新";
        break;

      case "DELETE_VALUE":
        text = "刪除";
        break;

      case "CREATE_RELATION":
        text = "創建關聯";
        break;

      case "UPDATE_RELATION":
        text = "更新關聯";
        break;

      case "DELETE_RELATION":
        text = "刪除關聯";
        break;

      default:
        break;
    }
    return text;
  };
}

export class OzCIRelation extends OzViewModel {
  constructor(
    no = null,
    source_ci_no = null,
    target_ci_no = null,
    deleted = false,
    custom_field_value_no = null,
    description = "",
    source_ci = new OzCI(),
    target_ci = new OzCI()
  ) {
    super();
    this.no = no;
    this.source_ci_no = source_ci_no;
    this.target_ci_no = target_ci_no;
    this.deleted = deleted;
    this.custom_field_value_no = custom_field_value_no;
    this.description = description;
    this.desc = description;

    this.source_ci = new OzCI().fromDict(source_ci);
    this.target_ci = new OzCI().fromDict(target_ci);
  }

  fromDict(relationDict) {
    super.fromDict(relationDict);

    if (!isNullOrUndefined(this.description) && this.desc !== undefined) {
      this.desc = this.description;
    }

    if (!isNullOrUndefined(relationDict)) {
      this.source_ci = new OzCI().fromDict(relationDict.source_ci);
      this.target_ci = new OzCI().fromDict(relationDict.target_ci);
    }

    return this;
  }
}

export class RelationDefaultDescription {
  constructor(no = null, class_no = null, relation_type = "", target_class_no = null, description = "", index = null) {
    this.no = no;
    this.class_no = class_no;
    this.relation_type = relation_type;
    this.target_class_no = target_class_no;
    this.description = description;
    this.index = index;
  }
}

export class CmdbSearchObject {
  importByCI = (ci) => {
    // 一般欄位
    const attrs = ["no", "name", "enable", "description", "memo", "version", "class_no"];
    attrs.forEach((attr) => {
      if (!isEmptyString(ci[attr])) {
        this[attr] = ci[attr];
      }
    });

    // 自訂欄位
    const fvs = ci.field_values ? ci.field_values : [];
    const filterCIFV = fvs.filter(
      (fv) => !isEmptyString(fv.value) || (Array.isArray(fv.options) && !isEmptyArray(fv.options))
    );

    const fieldValues = [];
    filterCIFV.forEach((fv) => {
      const { field_no, value, options } = fv;
      let obj = {};

      if (Array.isArray(options) && options.length > 0) {
        const items = options
          .map((option) => option.item_no)
          .filter((item_no) => !isEmptyString(item_no) && item_no !== "null");
        if (items.length > 0) {
          obj = { field_no, options: items };
        }
      } else if (!isEmptyString(value)) {
        obj = { field_no, value };
      }

      if (!isEmptyObject(obj)) {
        fieldValues.push(obj);
      }
    });
    this.field_values = fieldValues;

    return this;
  };
}

export class TreeBeardNode {
  // name: 顯示名稱
  // toggle: 預設打開與否
  // active: 已選 / 未選
  // children: 子節點們
  constructor(no = null, name = "", parentNo = null, toggle = false, children = []) {
    this.no = no;
    this.name = name;
    this.parentNo = parentNo;
    this.toggle = toggle;
    this.children = children;
  }
}
