const initState = {
    userData: null,
    errorcode: null
}

const authReducer = (state = initState, action) => {
    
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                userData: action.payload,
                errorcode: null,
            };
        case 'REGISTER_SUCCESS':
            return {
                ...state,
                userData: action.payload,
                errorcode: null,
            };
        case 'LOGIN_ERROR':
            const errorcode = action.payload ? action.payload : 500;
            return {
                ...state,
                userData: null,
                errorcode: errorcode,
            };
        default:
            return state;
    }
}

export default authReducer