import { produce } from "immer";

const initState = {
  escalation_by_ticket: {},
  escalation_rule_item: [],
  errorcode: null,
};

const escalationReducer = produce((state = initState, action) => {
  switch (action.type) {
    case "Error":
      // console.log(action)
      return {
        ...state,
        // tickets: null,
        errorcode: action.payload ? action.payload : 500,
      };

    case "EscalationByTicketNo_SUCCESS":
      state.escalation_by_ticket[action.ticket_no] = action.payload;
      return state;
      // return {
      //   ...state,
      //   escalation_by_ticket: {
      //     ...state.escalation_by_ticket,
      //   },
      //   errorcode: null,
      // };
    case "EscalationRuleItem_SUCCESS":
      state.escalation_rule_item = [...state.escalation_rule_item, action.payload]
      return state;
      // return {
      //   ...state,
      //   escalation_rule_item: [...state.escalation_rule_item, action.payload],
      //   errorcode: null,
      // };
    default:
      return state;
  }
});

export default escalationReducer;
