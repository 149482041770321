
export class OzMailContent{
    constructor(){
        this.no = null;
        this.from_mail = null;
        this.subject = null;
        this.text_body = null;
        this.flowza_ticket_no = null;
        this.create_dt = null;
        this.is_task_check = null;
        this.is_incident_check = null;
        this.cc = null;
    }
}
